import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import { getLeadActivityTimeline } from 'config/helpers/Helpers'
import { pipelineStages } from 'config/constants/Constants'

function Activity(props) {

    const lead = props.lead
    const tasks = props.tasks
    //const tasks = props.tasks.sort((a, b) => {return Date.parse(a.date) - Date.parse(b.date)})
    const activity = getLeadActivityTimeline(lead, tasks)
    console.log('activity: ', activity)

    function getCategoryString(category) {
        let cat = 'Note'
        if (category === 'Callback') {
            cat = 'Callback Enquiry'
        }
        if (category === 'email') {
            cat = 'Email Enquiry'
        }
        if (category === 'Voicemail') {
            cat = 'Voicemail Enquiry'
        }
        return cat
    }
    
    return (
        <Grid container className='activity-container'>
            <Grid item xs={12} lg={12}>
                {activity.length > 0 ? (
                    <>
                        {activity.map((item, i) => {
                            return (
                                <Box style={{marginBottom: '1rem', borderBottom: '1px solid #eee'}} key={i}>
                                    {item.category === 'note' &&
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Grid container justifyContent='space-between'>
                                                    <Grid item>
                                                        <p style={{fontWeight: '700'}}>{getCategoryString(item.note.category)}</p>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <p>{item.note.note}</p>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container justifyContent='space-between'>
                                                    <Grid item>
                                                    {!item.note.system &&
                                                        <p style={{textAlign: 'left', fontSize: '0.75rem'}} className='hover-text' onClick={() => props.editNote(item.note)}>Edit</p>
                                                    }
                                                    </Grid>
                                                    <Grid item>
                                                        <p style={{textAlign: 'right', fontSize: '0.75rem'}}>{moment(item.note.createdAt).format('Do MMMM YYYY HH:mm')}</p>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                    {item.category === 'stage-updated' &&
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <p style={{fontWeight: '700'}}>Stage updated to {pipelineStages[item.label]}</p>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <p style={{textAlign: 'right', fontSize: '0.75rem'}}>{moment(item.timestamp).format('Do MMMM YYYY HH:mm')}</p>
                                            </Grid>
                                        </Grid>
                                    }
                                    {item.category === 'broker-updated' &&
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <p style={{fontWeight: '700'}}>Broker updated to {item.label}</p>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <p style={{textAlign: 'right', fontSize: '0.75rem'}}>{moment(item.timestamp).format('Do MMMM YYYY HH:mm')}</p>
                                            </Grid>
                                        </Grid>
                                    }
                                    {item.category === 'document' &&
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <p style={{fontWeight: '700'}}>Document</p>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <p>{item.label}</p>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <p style={{textAlign: 'right', fontSize: '0.75rem'}}>{moment(item.timestamp).format('Do MMMM YYYY HH:mm')}</p>
                                            </Grid>
                                        </Grid>
                                    }
                                    {item.category === 'core' &&
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <p style={{fontWeight: '700'}}>{item.label}</p>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <p style={{textAlign: 'right', fontSize: '0.75rem'}}>{moment(item.timestamp).format('Do MMMM YYYY HH:mm')}</p>
                                            </Grid>
                                        </Grid>
                                    }
                                    {item.category === 'call-preference' &&
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <p style={{fontWeight: '700'}}>{item.label}</p>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <p>{item.note}</p>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <p style={{textAlign: 'right', fontSize: '0.75rem'}}>{moment(item.timestamp).format('Do MMMM YYYY HH:mm')}</p>
                                            </Grid>
                                        </Grid>
                                    }
                                </Box>
                            )
                        })}
                    </>
                ) : (
                    <Box style={{marginBottom: '1rem'}}>
                        <Grid container>
                            <Grid item xs={12}>
                                <p>No activity</p>
                            </Grid>
                        </Grid>
                    </Box>	
                )}
            </Grid>
        </Grid>
    )
}

export default Activity