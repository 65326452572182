import { Component } from 'react'
import { API, Auth } from 'aws-amplify'
import config from './Config'

const apiPath = config.apiGatewayBroker.PATH

class BrokerClient extends Component {
	async addDetails(phone, permissions, firstName, lastName, email) {
		const info = await Auth.currentUserInfo()
		const data = {
			companyId: info.attributes['custom:company_id'],
			phone,
			permissions,
			firstName,
			lastName,
			email
		}
		console.log(data)

		return API.post(apiPath, 'brokers/add', {
			body: data
		})
	}

	async getDetails() {
		const info = await Auth.currentUserInfo()
		const data = {
			companyId: info.attributes['custom:company_id']
		}
		console.log(data)

		return API.post(apiPath, 'brokers/get', {
			body: data
		})
	}

	async getBrokers() {
		const info = await Auth.currentUserInfo()
		const data = {
			companyId: info.attributes['custom:company_id']
		}
		return API.post(apiPath, 'brokers/list', {
			body: data
		})
	}

	async getApplications() {
        const info = await Auth.currentUserInfo()
		const data = {
			companyId: info.attributes['custom:company_id']
		}
		console.log(data)

		return API.post(apiPath, 'applications/list', {
			body: data
		})
	}

	async updateStage(phone, stage) {
		const info = await Auth.currentUserInfo()
		const data = {
			companyId: info.attributes['custom:company_id'],
			phone,
			stage
		}
		console.log(data)

		return API.post(apiPath, 'applications/update-stage', {
			body: data
		})
	}

	async updateBroker(phone, brokerId, brokerName) {
		const info = await Auth.currentUserInfo()
		const data = {
			companyId: info.attributes['custom:company_id'],
			phone,
			brokerId,
			brokerName
		}
		console.log(data)

		return API.post(apiPath, 'applications/update-broker', {
			body: data
		})
	}

	async addNote(phone, note) {
		const info = await Auth.currentUserInfo()
		const data = {
			companyId: info.attributes['custom:company_id'],
			phone,
			note
		}
		console.log(data)

		return API.post(apiPath, 'applications/add-note', {
			body: data
		})
	}

	async updateNote(phone, updatedNote, index) {
		const info = await Auth.currentUserInfo()
		const data = {
			companyId: info.attributes['custom:company_id'],
			phone,
			updatedNote,
			index
		}
		console.log(data)

		return API.post(apiPath, 'applications/update-note', {
			body: data
		})
	}

	updateComplianceDocuments(companyId, phone, details) {
		const data = {
			companyId,
			phone,
			details
		}
		console.log(data)

		return API.post(apiPath, 'applications/update-compliance-documents', {
			body: data
		})
	}

	updateProviderDetails(companyId, phone, details) {
		const data = {
			companyId,
			phone,
			details
		}
		console.log(data)

		return API.post(apiPath, 'applications/update-provider-details', {
			body: data
		})
	}

	async saveApplication(phone, personalDetails, pensionDetails, riskDetails, complianceDocuments, providerDetails) {
		const info = await Auth.currentUserInfo()
		const data = {
			companyId: info.attributes['custom:company_id'],
			phone,
			personalDetails,
			pensionDetails,
			riskDetails,
			complianceDocuments,
			providerDetails
		}
		console.log(data)

		return API.post(apiPath, 'applications/update-application', {
			body: data
		})
	}
} 

export default BrokerClient