import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import PropTypes from 'prop-types'
import PersonalDetails from 'components/customer/Details/PersonalDetails'
import PensionDetails from 'components/customer/Details/PensionDetails'
import PensionTransferDetails from 'components/customer/Details/PensionTransferDetails'
import RetirementPlanningDetails from 'components/customer/Details/RetirementPlanningDetails'
import RiskDetails from 'components/customer/Details/RiskDetails'
import DocumentUpload from 'components/customer/Details/DocumentUpload'
import Activity from 'components/broker/Activity'
import AddNote from 'components/broker/AddNote'
import EditNote from 'components/broker/EditNote'
import BrokerAPI from 'config/api/BrokerAPI'
import Loader from 'components/general/Loader'
import ShowContentCard from 'components/general/ShowContentCard'
import ProviderDetails from 'components/broker/ProviderDetails'
import ConfirmContributionModal from 'components/broker/ConfirmContributionModal'
import ConfirmValueModal from 'components/broker/ConfirmValueModal'
import { onError } from 'config/lib/errorLib'
import { pipelineStages, pipelineStageColors } from 'config/constants/Constants'
import { NotificationManager } from 'react-notifications'
import { getComplete } from 'config/helpers/Helpers'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'
import componentStylesButton from 'assets/theme/components/button.js'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)
const useStylesButton = makeStyles(componentStylesButton)

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='down' ref={ref} {...props} />
})

function TabPanel(props) {
	const { children, value, index, ...other } = props
  
	return (
	  	<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			key={index}
			{...other}
	  	>
			{value === index && (
		  		<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
		  		</Box>
			)}
	  	</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
}

function a11yProps(index) {
	return {
	  	id: `simple-tab-${index}`,
	  	'aria-controls': `simple-tabpanel-${index}`,
	};
}

const styles = {
	root: {
	  display: "flex",
	  "&$selected": {
		display: "flex"
	  }
	},
	selected: {}
};

export default function ApplicationModal(props) {
	const classes = {...useStyles(), ...useStylesDialog(), ...useStylesButton()}
	const api = new BrokerAPI()

	const details = props.details
	const brokerOptions = {}
	for (const broker of props.brokers) {
		brokerOptions[broker.brokerId] = broker.firstName + ' ' + broker.lastName
	}
	console.log(brokerOptions)
	
	const [application, setApplication] = useState(props.application)
	const [complete, setComplete] = useState(getComplete(application))
	const [value, setValue] = useState(0)
    const [loading, setLoading] = useState(false)
    const [showPersonalDetails, setShowPersonalDetails] = useState(false)
	const [personalDetails, setPersonalDetails] = useState(application.personalDetails)
    const [showRiskDetails, setShowRiskDetails] = useState(false)
	const [riskDetails, setRiskDetails] = useState(application.riskDetails)
    const [showPensionDetails, setShowPensionDetails] = useState(false)
	const [pensionDetails, setPensionDetails] = useState(application.pensionDetails)
    const [showDocumentUpload, setShowDocumentUpload] = useState(false)
	const [showComplianceDocuments, setShowComplianceDocuments] = useState(false)
	const [complianceDocuments, setComplianceDocuments] = useState(application.complianceDocuments)
	const [showProviderDetails, setShowProviderDetails] = useState(false)
	const [providerDetails, setProviderDetails] = useState(application.providerDetails)
	const [showAddNote, setShowAddNote]  = useState(false)
	const [showEditNote, setShowEditNote]  = useState(false)
	const [note, setNote] = useState(null)
	const [showConfirmContributionModal, setShowConfirmContributionModal] = useState(false)
	const [showConfirmValueModal, setShowConfirmValueModal] = useState(false)
	const [alert, setAlert] = useState(false)

	useEffect(() => {
        console.log(application.activity.length)
		if (application.activity.length > 0) {
			handleTabChange(null, 1)
		}
    }, [])

	const handleTabChange = (event, newValue) => {
		setValue(newValue)
	}

	function updateApplication(details) {
		setApplication(details)
		setPersonalDetails(details.personalDetails)
		setPensionDetails(details.pensionDetails)
		setRiskDetails(details.riskDetails)
		setComplianceDocuments(details.complianceDocuments)
		setProviderDetails(details.providerDetails)
		setComplete(getComplete(details))
		props.updateApplication(details)
	}

	async function saveApplication() {
		try {
            let result = await api.saveApplication(application.phone, personalDetails, pensionDetails, riskDetails, complianceDocuments, providerDetails)
            console.log(result)

            props.updateApplication(result)
            NotificationManager.success('Application Saved')
			props.close()
        } catch(e) {
            onError(e)
        }
	}

	async function confirmContribution(application) {
		updateApplication(application)
		await saveApplication()
		await updateStage('won', true)
		setShowConfirmContributionModal(false)
	}

	async function confirmValue(application) {
		updateApplication(application)
		await saveApplication()
		await updateStage('won', true)
		setShowConfirmValueModal(false)
	}

	async function updateStage(stage, skipCheck = false) {
		console.log('update stage: ', stage)
		if (stage === 'won' && !skipCheck) {
			if (application.type === 'Pension Review' || application.type === 'Retirement Planning') {
				setShowConfirmValueModal(true)
			}
			else {
				setShowConfirmContributionModal(true)
			}
		}
		else {
			try {
				let result = await api.updateStage(application.phone, stage)
				console.log(result)

				setApplication(result)
				//setLoading(false)
				props.updateApplication(result)
				NotificationManager.success('Stage updated')
			} catch(e) {
				onError(e)
			}
		}
	}

	async function updateBroker(newBroker) {
		console.log('update broker: ', newBroker)
		try {
			let result = await api.updateBroker(application.phone, newBroker, brokerOptions[newBroker])
			console.log(result)

			setApplication(result)
			//setLoading(false)
			props.updateApplication(result)
			NotificationManager.success('Broker updated')
		} catch(e) {
			onError(e)
		}
	}

	function openEditNote(note) {
		setNote(note)
		setShowEditNote(true)
	}

	function checkUnsavedChanges() {
		let savedPersonalDetails = JSON.stringify(application.personalDetails, Object.keys(application.personalDetails).sort())
		let unsavedPersonalDetails = JSON.stringify(personalDetails, Object.keys(personalDetails).sort())
		let personalSaved = savedPersonalDetails === unsavedPersonalDetails
		console.log('personal equal: ', personalSaved)

		let savedPensionDetails = JSON.stringify(application.pensionDetails, Object.keys(application.pensionDetails).sort())
		let unsavedPensionDetails = JSON.stringify(pensionDetails, Object.keys(pensionDetails).sort())
		let pensionSaved = savedPensionDetails === unsavedPensionDetails
		console.log('pension equal: ', pensionSaved)

		let savedRiskDetails = JSON.stringify(application.riskDetails, Object.keys(application.riskDetails).sort())
		let unsavedRiskDetails = JSON.stringify(riskDetails, Object.keys(riskDetails).sort())
		let riskSaved = savedRiskDetails === unsavedRiskDetails
		console.log('risk equal: ', riskSaved)

		let savedComplianceDocuments = JSON.stringify(application.complianceDocuments, Object.keys(application.complianceDocuments).sort())
		let unsavedComplianceDocuments = JSON.stringify(complianceDocuments, Object.keys(complianceDocuments).sort())
		let complianceSaved = savedComplianceDocuments === unsavedComplianceDocuments
		console.log('compliance equal: ', complianceSaved)

		let savedProviderDetails = JSON.stringify(application.providerDetails, Object.keys(application.providerDetails).sort())
		let unsavedProviderDetails = JSON.stringify(providerDetails, Object.keys(providerDetails).sort())
		let providerSaved = savedProviderDetails === unsavedProviderDetails
		console.log('provider equal: ', providerSaved)

		if (personalSaved && pensionSaved && riskSaved && complianceSaved && providerSaved) {
			return false
		}
		else {
			return true
		}
	}

	function close() {
		let unsaved = checkUnsavedChanges()
		console.log('unsaved: ', unsaved)
		if (unsaved) {
			showUnsavedChangesWarning()
		}
		else {
			props.close()
		}
	}

	function showUnsavedChangesWarning() {
        setAlert(
            <ReactBSAlert
                warning
                style={{ display: 'block', marginTop: '-100px' }}
                title='Would you like to save your changes?'
                onConfirm={() => saveApplication()}
                onCancel={() => setAlert(false)}
                cancelBtnCssClass='btn-secondary'
                confirmBtnBsStyle='success'
                cancelBtnText='Cancel'
                confirmBtnText='Save & Close'
                showCancel
                btnSize=''
            >
                You have unsaved changes for this application.
            </ReactBSAlert>
        )
    }

	return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => close()}
			maxWidth='xl'
			className='lead-modal'
			//className='fixed-height-modal'
			//fullScreen
		>
			{alert}
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					{application.personalDetails.firstName + ' ' + application.personalDetails.lastName} (<a href={'tel:' + application.phone}>{application.phone}</a> | <a href={'mailto:' + application.personalDetails.email}>{application.personalDetails.email}</a>)
				</Typography>
				<IconButton onClick={() => close()}>
					<Clear />
				</IconButton>
			</div>
			
			<DialogContent style={{paddingTop: '0'}}>
				<Grid container alignItems='center' justifyContent='center' style={{marginBottom: '1rem'}}>
					<Grid item xs={3}>
						<FormControl variant='outlined' fullWidth direction='row'>
							<FormLabel>Application Stage</FormLabel>
							<Select
								multiple={false}
								IconComponent={KeyboardArrowDown}
								value={application.stage}
								onChange={(e) => updateStage(e.target.value)}
								className='stage-select'
							>
								{Object.keys(pipelineStages).map((key) => {
									return (
										<MenuItem 
											value={key}
											classes={styles}
										>
											<Box className='stage-color' style={{backgroundColor: pipelineStageColors[key]}}></Box> 
											<Box>{pipelineStages[key]}</Box>
										</MenuItem>
									)
								})}
							</Select>
						</FormControl>
					</Grid>
					{details.permissions === 'admin' &&
						<Grid item xs={3}>
							<FormControl variant='outlined' fullWidth direction='row'>
								<FormLabel>Assigned Broker</FormLabel>
								<Select
									multiple={false}
									IconComponent={KeyboardArrowDown}
									value={application.brokerId}
									onChange={(e) => updateBroker(e.target.value)}
								>
									{Object.keys(brokerOptions).map((key) => {
										return (<MenuItem value={key}>{brokerOptions[key]}</MenuItem>)
									})}
								</Select>
							</FormControl>
						</Grid>
					}
				</Grid>
				{(!showAddNote && !showEditNote) &&
					<Grid container>
						<Grid item xs={2}>
							<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
								<Tabs 
									value={value} 
									onChange={handleTabChange} 
									orientation='vertical'
									//aria-label='basic tabs example'
								>
									<Tab label='Application' {...a11yProps(0)} />
									<Tab label='Activity' {...a11yProps(1)} />
								</Tabs>
							</Box>
						</Grid>

						<Grid item xs={10}>
							<TabPanel value={value} index={0}>
								<Grid container>
									<Grid item lg={12}>
                                        <ShowContentCard 
                                            title='Personal Details'
                                            showContent={showPersonalDetails}
                                            setShowContent={(show) => setShowPersonalDetails(show)}
                                            content={
                                                <PersonalDetails 
                                                    phone={application.phone}
													lastActive={application.lastActive}
                                                    details={application.personalDetails}
													updatePersonalDetails={(details) => setPersonalDetails(details)}
                                                    updateApplication={(application) => updateApplication(application)}
                                                    closeContent={() => setShowPersonalDetails(false)}
                                                    broker
                                                />
                                            }
											complete={complete.personalDetails}
                                        />
									</Grid>
									<Grid item xs={12}>
                                        <ShowContentCard 
                                            title={
												<>
													{application.type === 'Pension Transfer' &&
														<>Pension Transfer Details</>
													}
													{application.type === 'Retirement Planning' &&
														<>Retirement Planning Details</>
													}
													{(!application.type || application.type === 'Start Pension') &&
														<>Start Pension Details</>
													}
												</>
											}
                                            showContent={showPensionDetails}
                                            setShowContent={(show) => setShowPensionDetails(show)}
                                            content={
												<>
													{application.type === 'Pension Transfer' &&
														<PensionTransferDetails 
															details={application.pensionDetails}
															applicantPhone={application.phone}
															updatePensionDetails={(details) => setPensionDetails(details)}
															updateApplication={(application) => updateApplication(application)}
															closeContent={() => setShowPensionDetails(false)}
															stage={application.stage}
															broker
														/>
													}
													{application.type === 'Retirement Planning' &&
														<RetirementPlanningDetails 
															details={application.pensionDetails}
															applicantPhone={application.phone}
															updatePensionDetails={(details) => setPensionDetails(details)}
															updateApplication={(application) => updateApplication(application)}
															closeContent={() => setShowPensionDetails(false)}
															stage={application.stage}
															broker
														/>
													}
													{(!application.type || application.type === 'Start Pension') &&
														<PensionDetails 
															details={application.pensionDetails}
															applicantPhone={application.phone}
															updatePensionDetails={(details) => setPensionDetails(details)}
															updateApplication={(application) => updateApplication(application)}
															closeContent={() => setShowPensionDetails(false)}
															broker
														/>
													}
												</>
                                            }
											complete={complete.pensionDetails}
                                        />
									</Grid>
                                    <Grid item xs={12}>
                                        <ShowContentCard 
                                            title='Risk Details'
                                            showContent={showRiskDetails}
                                            setShowContent={(show) => setShowRiskDetails(show)}
                                            content={
                                                <RiskDetails 
                                                    details={application.riskDetails}
													applicantPhone={application.phone}
													updateRiskDetails={(details) => setRiskDetails(details)}
                                                    updateApplication={(application) => updateApplication(application)}
                                                    closeContent={() => setShowRiskDetails(false)}
                                                    broker
                                                />
                                            }
											complete={complete.riskDetails}
                                        />
									</Grid>
									{/*
                                    <Grid item xs={12}>
                                        <ShowContentCard 
                                            title='Document Upload'
                                            showContent={showDocumentUpload}
                                            setShowContent={(show) => setShowDocumentUpload(show)}
                                            content={
                                                <DocumentUpload 
                                                    details={application.documents}
													applicantPhone={application.phone}
                                                    updateApplication={(application) => updateApplication(application)}
                                                    closeContent={() => setShowDocumentUpload(false)}
                                                    broker
                                                />
                                            }
											complete={complete.documents}
                                        />
									</Grid>
									<Grid item xs={12}>
                                        <ShowContentCard 
                                            title='Compliance Documents'
                                            showContent={showComplianceDocuments}
                                            setShowContent={(show) => setShowComplianceDocuments(show)}
                                            content={
                                                <ComplianceDocuments 
                                                    details={application.complianceDocuments}
													applicantPhone={application.phone}
													updateComplianceDocuments={(details) => setComplianceDocuments(details)}
                                                    updateApplication={(application) => updateApplication(application)}
                                                    closeContent={() => setShowComplianceDocuments(false)}
                                                    broker
                                                />
                                            }
											complete={complete.complianceDocuments}
                                        />
									</Grid>
									*/}
									<Grid item xs={12}>
                                        <ShowContentCard 
                                            title='Pension Provider Details'
                                            showContent={showProviderDetails}
                                            setShowContent={(show) => setShowProviderDetails(show)}
                                            content={
                                                <ProviderDetails 
                                                    details={application.providerDetails}
													applicantPhone={application.phone}
													updateProviderDetails={(details) => setProviderDetails(details)}
                                                    updateApplication={(application) => updateApplication(application)}
                                                    closeContent={() => setShowProviderDetails(false)}
                                                    broker
                                                />
                                            }
											complete={complete.providerDetails}
                                        />
									</Grid>
								</Grid>
							</TabPanel>
							<TabPanel value={value} index={1}>
								{!loading ? (
									<Activity
										lead={application}
										editNote={(note) => openEditNote(note)}
									/>
								) : (
									<Grid container justifyContent='flex-start'>
										<Grid item xs={12}>
											<Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
												<Loader />
											</Box>
										</Grid>
									</Grid>
								)}
							</TabPanel>
						</Grid>
					</Grid>
				}

				<Grid container alignItems='center' justifyContent='center'>
					<Grid item xs={10}>
						{showAddNote &&
							<AddNote 
								lead={application}
								updateLead={(application) => updateApplication(application)}
								openActivity={() => handleTabChange(null, 1)}
								hide={() => setShowAddNote(false)}
							/>
						}
						{showEditNote &&
							<EditNote 
								lead={application}
								note={note}
								updateLead={(application) => updateApplication(application)}
								openActivity={() => handleTabChange(null, 1)}
								hide={() => setShowEditNote(false)}
							/>
						}
					</Grid>
				</Grid>
			</DialogContent>
			
			{!showAddNote &&
				<DialogActions>
					<Button
						onClick={() => props.close()}
						color='primary'
					>
						Close
					</Button>

					<Box marginLeft='auto!important'>
                        <Button
							component={Box}
							onClick={() => setShowAddNote(true)}
							color='primary'
							variant='contained'
							//disabled={checkFields()}
						>
							Add Note
						</Button>
					</Box>
				</DialogActions>
			}

			{showConfirmContributionModal &&
                <ConfirmContributionModal 
                    isOpen={showConfirmContributionModal}
                    close={() => setShowConfirmContributionModal(false)}
                    showSuccessAlert={(text) => props.showSuccessAlert(text)}
					application={application}
                    confirmContribution={(application) => confirmContribution(application)}
                />
            }
			{showConfirmValueModal &&
                <ConfirmValueModal 
                    isOpen={showConfirmValueModal}
                    close={() => setShowConfirmValueModal(false)}
                    showSuccessAlert={(text) => props.showSuccessAlert(text)}
					application={application}
                    confirmValue={(application) => confirmValue(application)}
                />
            }
		</Dialog>
	)
}