import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import ApplicationModal from 'components/broker/ApplicationModal'
import PipelineFilter from 'components/broker/PipelineFilter'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter'
import { getPercentageComplete, getSiteClass } from 'config/helpers/Helpers'
import moment from 'moment'

import DetailsContext from 'config/context/DetailsContext'

import componentStyles from 'assets/theme/views/admin/react-bs-table.js'

const useStyles = makeStyles(componentStyles)

const styles = {
	root: {
	  display: "flex",
	  "&$selected": {
		display: "flex"
	  }
	},
	//selected: {}
};

function ApplicationsTable(props) {
    const bstable: any = React.useRef()
    const classes = { ...useStyles() }
    const applications = props.applications
    const details = props.details
    const companyDetails = useContext(DetailsContext)
    const companyId = companyDetails.companyId
    
    const [application, setApplication] = useState(null)
    const [showApplicationModal, setShowApplicationModal] = useState(false)
    const [selected, setSelected] = useState([])
    const [alert, setAlert] = useState(false)

    function selectApplication(application) {
        let newSelected = [...selected]
        newSelected.push(application)
        setSelected(newSelected)
    }

    function deselectApplication(application) {
        let newSelected = [...selected]
        let index = selected.findIndex(item => item.phone === application.phone)
        newSelected.splice(index, 1)
        setSelected(newSelected)
    }

    const selectRow = {
        mode: 'checkbox',
        selectColumnStyle: { textAlign: 'center', margin: 'auto', width: '50px' },
        onSelect: (row, isSelect, selected, e) => {
            if (isSelect) {
                selectApplication(row)
            }
            else {
                deselectApplication(row)
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            if (isSelect) {
                setSelected(rows)
            }
            else {
                setSelected([])
            }
        }
    }

    const columns = [
        {
            dataField: 'firstName',
            text: 'Name',
            filter: textFilter(),
            formatter: (cell, row) => {
                if (!row.brokerId || (row.brokerId === details.brokerId) || details.permissions === 'admin') {
                    return (<Box className={'hover-text ' + getSiteClass(companyId)} onClick={() => openApplicationModal(row)}>{row.personalDetails.firstName + ' ' + (row.personalDetails.lastName ? row.personalDetails.lastName : '')}</Box>)
                }
                else {
                    return (<Box>{row.personalDetails.firstName + ' ' + row.personalDetails.lastName}</Box>)
                }
            },
            headerFormatter: (col, colIndex, components) => {
                return components.filterElement
            }
        },
        {
            dataField: 'brokerName',
            text: 'Broker',
            sort: true,
            formatter: (cell, row) => {
                return <>{row.brokerName || '-'}</>
            },
        },
        {
            dataField: 'phone',
            text: 'Phone',
            sort: true,
            formatter: (cell, row) => {
                if ((row.brokerId === details.brokerId) || details.permissions === 'admin') {
                    return (<Box>{row.phone}</Box>)
                }
                else {
                    return (<Box style={{filter: 'blur(5px)'}}>+353xxxxxxxxx</Box>)
                }
            },
        },
        {
            dataField: 'email',
            text: 'Email',
            sort: true,
            formatter: (cell, row) => {
                if ((row.brokerId === details.brokerId) || details.permissions === 'admin') {
                    return (<Box>{row.personalDetails.email ? row.personalDetails.email : '-'}</Box>)
                }
                else {
                    return (<Box style={{filter: 'blur(5px)'}}>xxxxx@xxxx.xxx</Box>)
                }
            },
        },
        {
            dataField: 'type',
            text: 'Type',
            sort: true,
            formatter: (cell, row) => {
                return <>{row.type ? row.type : 'Start Pension'}</>
            },
        },
        {
            dataField: 'contribution',
            text: 'Contribution',
            sort: true,
            formatter: (cell, row) => {
                return <>{row.pensionDetails.contribution ? '€' + row.pensionDetails.contribution : '-'}</>
            },
        },
        {
            dataField: 'annualEarnings',
            text: 'Salary',
            sort: true,
            formatter: (cell, row) => {
                return <>{row.pensionDetails.annualEarnings ? '€' + row.pensionDetails.annualEarnings : '-'}</>
            },
        },
        {
            dataField: 'progress',
            text: 'Progress',
            sort: true,
            formatter: (cell, row) => {
                return <>{getPercentageComplete(row)}</>
            },
        },
        {
            dataField: 'daysInStage',
            text: 'In Stage',
            sort: true,
            formatter: (cell, row) => {
                let day = row.createdAt
                
                for (var item of row.activity) {
                    if (item.category === 'stage-updated') {
                        day = item.timestamp
                    }
                }
                return <>{moment(day).fromNow()}</>
            }
        },
        {
            dataField: 'lastActive',
            text: 'Last Active',
            sort: true,
            formatter: (cell, row) => {
                return <>{row.lastActive ? moment(row.lastActive).fromNow() : 'Unverified Phone'}</>
            }
        },
        {
            dataField: 'createdAt',
            text: 'Created',
            sort: true,
            formatter: (cell, row) => {
                return <>{moment(row.createdAt).fromNow()}</>
            }
        }
    ]

    function openApplicationModal(application) {
        // only allow opening of unclaimed and owned leads
        if (details.permissions === 'admin') {
            setApplication(application)
            setShowApplicationModal(true)
        }
        else {
            if (application.brokerId === details.brokerId) {
                setApplication(application)
                setShowApplicationModal(true)
            }
        }
    }

    function closeApplicationModal() {
        setShowApplicationModal(false)
        setApplication(null)
    }
    
    return (
        <Card>
            {alert}
            <CardHeader
                subheader={
                    <>
                        <Box display='flex' style={{justifyContent: 'space-between'}}>
                            <Box>
                                <Box
                                    component={Typography}
                                    variant='h2'
                                    marginBottom='0!important'
                                >
                                    <Box component='span'>Pension Applications</Box>
                                </Box>
                            </Box>
                            {/*
                                <Box>
                                    <Button
                                        component={Box}
                                        onClick={() => setShowAddApplicationModal(true)}
                                        color='primary'
                                        variant='contained'
                                    >
                                        Add Application
                                    </Button>
                                </Box>
                            */}
                        </Box>
                        <Box>
                            <PipelineFilter 
                                pipelineCount={props.pipelineCount}
                                updateStage={(stage) => props.updateStage(stage)}
                                stage={props.stage}
                                companyId={companyId}
                            />
                        </Box>
                    </>
                }
                classes={{ root: classes.cardHeaderRoot }}
            ></CardHeader>

            <BootstrapTable 
                ref={bstable}
                keyField='phone' 
                data={ applications }
                columns={ columns }
                //selectRow={ selectRow }
                filter={ filterFactory() } 
                noDataIndication={<Box>No applications</Box>}
                className='custom-table'
            />

            {/*showAddApplicationModal &&
                <AddApplicationModal 
                    isOpen={showAddApplicationModal}
                    close={() => setShowAddApplicationModal(false)}
                    details={details}
                    brokers={props.brokers}
                    showSuccessAlert={(text) => props.showSuccessAlert(text)}
                    refreshApplications={() => props.refreshApplications()}
                />
            */}

            {showApplicationModal &&
                <ApplicationModal 
                    isOpen={showApplicationModal}
                    close={() => closeApplicationModal()}
                    application={application}
                    details={details}
                    brokers={props.brokers}
                    //claimLead={(lead) => props.claimLead(lead)}
                    updateApplication={(application) => props.updateApplication(application)}
                    removeApplication={(application, product) => props.removeApplication(application, product)}
                    //product={product}
                />
            }
        </Card>
    );
}

export default ApplicationsTable