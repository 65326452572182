import React, { useState, useContext } from 'react'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Loader from 'components/general/Loader'
import CustomerAPI from 'config/api/CustomerAPI'
import { onError } from 'config/lib/errorLib'
import { NotificationManager } from 'react-notifications'
import { Auth } from 'aws-amplify'
import { getAge, getSiteClass } from 'config/helpers/Helpers'
import InfoModal from 'components/general/InfoModal'

import DetailsContext from 'config/context/DetailsContext'

function RiskDetails(props) {
    const api = new CustomerAPI()
    const details = props.details
    const companyDetails = useContext(DetailsContext)
    const companyId = companyDetails.companyId
    const isBroker = props.broker

    const retirementAge = props.retirementAge || 65

    const [q1, setQ1] = useState(details.q1 ? details.q1 : (props.dob ? getAge(props.dob) : ''))
    const [q2, setQ2] = useState(details.q2 ? details.q2 : (props.dob ? retirementAge - getAge(props.dob) : ''))
    const [q3, setQ3] = useState(details.q3 || '')
    const [q4, setQ4] = useState(details.q4 || '')
    const [q5, setQ5] = useState(details.q5 || '')
    const [q6, setQ6] = useState(details.q6 || '')
    const [q7, setQ7] = useState(details.q7 || '')
    const [q8, setQ8] = useState(details.q8 || '')
    const [q9, setQ9] = useState(details.q9 || '')
    const [infoTitle, setShowInfoTitle] = useState('')
    const [showInfoModal, setShowInfoModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [answer, setAnswer] = useState(null)

    async function updateDetails() {
        setLoading(true)
        try {
            const info = await Auth.currentUserInfo()
            const phone = isBroker ? props.applicantPhone : info.attributes['phone_number']
            let details = {
                q1,
                q2,
                q3,
                q4,
                q5,
                q6,
                q7,
                q8,
                q9
            }
            let result = await api.updateRiskDetails(companyId, phone, details)

            props.updateApplication(result)
            if (props.broker) {
                props.closeContent()
            }
            else {
                //props.updateStage(3)
                props.submitApplication()
            }
            //NotificationManager.success('Details saved')
        } catch(e) {
            onError(e)
            setLoading(false)
        }
    }

    function updateAnswer(answer) {
        if (infoTitle === 'Investment Knowledge') {
            setQ3(answer)
        }
        if (infoTitle === 'Savings') {
            setQ8(answer)
        }
        if (infoTitle === 'Investment Impact') {
            setQ9(answer)
        }
    }

    function checkRequiredFields() {
        // if (firstName.length > 0 && lastName.length > 0) {
        //     return false
        // }
        // else {
        //     return true
        // }
        return false
    }

    function updateRiskDetails(field, value) {
        let details = {
            q1,
            q2,
            q3,
            q4,
            q5,
            q6,
            q7,
            q8,
            q9
        }
        details[field] = value
        if (isBroker) {
            props.updateRiskDetails(details)
        }
    }

    function openInfoModal(title, answer) {
        setShowInfoTitle(title)
        setAnswer(answer)
        setShowInfoModal(true)
    }

    function closeInfoModal() {
        setShowInfoModal(false)
        setAnswer(null)
        setShowInfoTitle('')
    }
    
    return (
        <Grid container>
            <Grid item xs={12} lg={12} style={{marginBottom: '1rem'}}>
                <Grid container>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>What age are you? (Years)</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <OutlinedInput
                                    fullWidth
                                    type='text'
                                    value={q1}
                                    onChange={(e) => { setQ1(e.target.value); updateRiskDetails('q1', e.target.value); }}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>How long do you plan to invest for? (Years)</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <OutlinedInput
                                    fullWidth
                                    type='text'
                                    value={q2}
                                    onChange={(e) => { setQ2(e.target.value); updateRiskDetails('q2', e.target.value); }}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormLabel>How would you describe your investment knowledge? <span className={'hover-text underline-text ' + getSiteClass(companyId)} onClick={() => openInfoModal('Investment Knowledge', q3)}>Read More</span></FormLabel>
                            <FormControl variant='outlined' fullWidth>
                                <Select
                                    multiple={false}
                                    IconComponent={KeyboardArrowDown}
                                    value={q3}
                                    onChange={(e) => { setQ3(e.target.value); updateRiskDetails('q3', e.target.value); }}
                                >
                                    <MenuItem value='Expert'>Expert</MenuItem>
                                    <MenuItem value='High'>High</MenuItem>
                                    <MenuItem value='Moderate'>Moderate</MenuItem>
                                    <MenuItem value='Little'>Little</MenuItem>
                                    <MenuItem value='None'>None</MenuItem>
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormLabel>What is your investment objective?</FormLabel>
                            <FormControl variant='outlined' fullWidth>
                                <Select
                                    multiple={false}
                                    IconComponent={KeyboardArrowDown}
                                    value={q4}
                                    onChange={(e) => { setQ4(e.target.value); updateRiskDetails('q4', e.target.value); }}
                                >
                                    <MenuItem value='Maximise returns, regardless of risk'>Maximise returns, regardless of risk</MenuItem>
                                    <MenuItem value='High returns, without excessive risk'>High returns, without excessive risk</MenuItem>
                                    <MenuItem value='Good returns, with limited risk'>Good returns, with limited risk</MenuItem>
                                    <MenuItem value='Returns which beat inflation, without significant risk'>Returns which beat inflation, without significant risk</MenuItem>
                                    <MenuItem value='Protect my capital'>Protect my capital</MenuItem>
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormLabel>How concerned would you be with a short-term (6 months) 10% decline in your investment?</FormLabel>
                            <FormControl variant='outlined' fullWidth>
                                <Select
                                    multiple={false}
                                    IconComponent={KeyboardArrowDown}
                                    value={q5}
                                    onChange={(e) => { setQ5(e.target.value); updateRiskDetails('q5', e.target.value); }}
                                >
                                    <MenuItem value='Not concerned'>Not concerned</MenuItem>
                                    <MenuItem value='Slightly concerned'>Slightly concerned</MenuItem>
                                    <MenuItem value='Moderately concerned'>Moderately concerned</MenuItem>
                                    <MenuItem value='Very concerned'>Very concerned</MenuItem>
                                    <MenuItem value='Extremely concerned'>Extremely concerned</MenuItem>
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormLabel>What is your appetite for taking risk?</FormLabel>
                            <FormLabel className='secondary-label'>If you invested €10,000 today, what range of potential values would you consider to be an acceptable return for your investment after 5 years?</FormLabel>
                            <FormControl variant='outlined' fullWidth>
                                <Select
                                    multiple={false}
                                    IconComponent={KeyboardArrowDown}
                                    value={q6}
                                    onChange={(e) => { setQ6(e.target.value); updateRiskDetails('q6', e.target.value); }}
                                >
                                    <MenuItem value='€6,300 - €17,200'>€6,300 - €17,200</MenuItem>
                                    <MenuItem value='€6,800 - €16,300'>€6,800 - €16,300</MenuItem>
                                    <MenuItem value='€7,900 - €14,000'>€7,900 - €14,000</MenuItem>
                                    <MenuItem value='€8,900 - €12,100'>€8,900 - €12,100</MenuItem>
                                    <MenuItem value='€9,800 - €11,600'>€9,800 - €11,600</MenuItem>
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormLabel>What is your approach to taking risks?</FormLabel>
                            <FormLabel className='secondary-label'>If you could increase the value of your investment by taking more risk, would you:</FormLabel>
                            <FormControl variant='outlined' fullWidth>
                                <Select
                                    multiple={false}
                                    IconComponent={KeyboardArrowDown}
                                    value={q7}
                                    onChange={(e) => { setQ7(e.target.value); updateRiskDetails('q7', e.target.value); }}
                                >
                                    <MenuItem value='Take a LOT more risk with ALL of your money'>Take a LOT more risk with ALL of your money</MenuItem>
                                    <MenuItem value='Take a LITTLE more risk with ALL of your money'>Take a LITTLE more risk with ALL of your money</MenuItem>
                                    <MenuItem value='Take a LITTLE more risk with SOME of your money'>Take a LITTLE more risk with SOME of your money</MenuItem>
                                    <MenuItem value='Be UNWILLING to take any more risk'>Be UNWILLING to take any more risk</MenuItem>
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormLabel>Do you have other savings available to you, should you encounter a financial emergency? <span className={'hover-text underline-text ' + getSiteClass(companyId)} onClick={() => openInfoModal('Savings', q8)}>Read More</span></FormLabel>
                            <FormControl variant='outlined' fullWidth>
                                <Select
                                    multiple={false}
                                    IconComponent={KeyboardArrowDown}
                                    value={q8}
                                    onChange={(e) => { setQ8(e.target.value); updateRiskDetails('q8', e.target.value); }}
                                >
                                    <MenuItem value='No'>No</MenuItem>
                                    <MenuItem value='Some'>Some</MenuItem>
                                    <MenuItem value='Yes'>Yes</MenuItem>
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormLabel>If this investment were to perform poorly, what impact would this have on your financial well-being? <span className={'hover-text underline-text ' + getSiteClass(companyId)} onClick={() => openInfoModal('Investment Impact', q9)}>Read More</span></FormLabel>
                            <FormControl variant='outlined' fullWidth>
                                <Select
                                    multiple={false}
                                    IconComponent={KeyboardArrowDown}
                                    value={q9}
                                    onChange={(e) => { setQ9(e.target.value); updateRiskDetails('q9', e.target.value); }}
                                >
                                    <MenuItem value='Big'>Big</MenuItem>
                                    <MenuItem value='Some'>Some</MenuItem>
                                    <MenuItem value='None'>None</MenuItem>
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>        
                <Grid container justifyContent='space-between'>
                    <Grid item>
                        {!props.broker &&
                            <Button
                                component={Box}
                                color='secondary'
                                variant='contained'
                                size='medium'
                                onClick={() => props.updateStage(1)}
                            >
                                Back
                            </Button>
                        }
                    </Grid>
                    <Grid item>
                        <Button
                            component={Box}
                            color='primary'
                            variant='contained'
                            width={180}
                            disabled={checkRequiredFields() || loading}
                            onClick={() => updateDetails()}
                        >
                            {!loading ? (
                                <>{!props.broker ? (
                                    <>Submit Application</>
                                ) : (
                                    <>Update</>
                                )}</>
                            ) : (
                                <Loader height={12} />
                            )}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            {showInfoModal &&
                <InfoModal 
                    isOpen={showInfoModal}
                    close={() => closeInfoModal()}
                    title={infoTitle}
                    updateAnswer={(answer) => updateAnswer(answer)}
                    answer={answer}
                />
            }
        </Grid>
    )
}

export default RiskDetails