import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'

import zurich from 'resources/images/zurich-logo.png'
import aviva from 'resources/images/aviva-logo.png'
import irishLife from 'resources/images/irish-life-logo.png'
import standardLife from 'resources/images/standard-life-logo.png'
import newIreland from 'resources/images/new-ireland-logo.png'
import help from 'resources/images/help-icon.png'

function SelectProvider(props) {
    const provider = props.provider
    
    return (
        <>
            <Grid container direction='row' justifyContent='center' alignItems='center' style={{marginTop: '1rem'}}>
                <Grid item xs={6} lg={2}>
                    <FormGroup className={'provider-form-group ' + (provider === 'Not Sure' ? 'selected' : '')}  onClick={() => props.updateProvider('Not Sure')}>
                        <img className='provider-logo' src={help} alt='not sure' />
                        <FormLabel>Not Sure</FormLabel>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} lg={2}>
                    <FormGroup className={'provider-form-group ' + (provider === 'Zurich' ? 'selected' : '')} onClick={() => props.updateProvider('Zurich')}>
                        <img className='provider-logo' src={zurich} alt='zurich' />
                        <FormLabel>Zurich</FormLabel>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} lg={2}>
                    <FormGroup className={'provider-form-group ' + (provider === 'Irish Life' ? 'selected' : '')}  onClick={() => props.updateProvider('Irish Life')}>
                        <img className='provider-logo' src={irishLife} alt='irish life' />
                        <FormLabel>Irish Life</FormLabel>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} lg={2}>
                    <FormGroup className={'provider-form-group ' + (provider === 'Aviva' ? 'selected' : '')}  onClick={() => props.updateProvider('Aviva')}>
                        <img className='provider-logo' src={aviva} alt='aviva' />
                        <FormLabel>Aviva</FormLabel>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} lg={2}>
                    <FormGroup className={'provider-form-group ' + (provider === 'Standard Life' ? 'selected' : '')}  onClick={() => props.updateProvider('Standard Life')}>
                        <img className='provider-logo' src={standardLife} alt='standard life' />
                        <FormLabel>Standard Life</FormLabel>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} lg={2}>
                    <FormGroup className={'provider-form-group ' + (provider === 'New Ireland' ? 'selected' : '')}  onClick={() => props.updateProvider('New Ireland')}>
                        <img className='provider-logo' src={newIreland} alt='new ireland' />
                        <FormLabel>New Ireland</FormLabel>
                    </FormGroup>
                </Grid>
            </Grid>
            {/*<Grid container direction='row' justifyContent='center' alignItems='center'>
                <Grid item xs={6} lg={2}>
                    <FormGroup className={'provider-form-group ' + (provider === 'No preference' ? 'selected' : '')}  onClick={() => props.updateProvider('No preference')}>
                        <img className='provider-logo' src={help} alt='aviva' />
                        <FormLabel>No Preference</FormLabel>
                    </FormGroup>
                </Grid>
            </Grid>*/}
        </>
    )
}

export default SelectProvider