import { Component } from 'react'
import { API, Auth } from 'aws-amplify'
import config from './Config'

const apiPath = config.apiGatewayCustomer.PATH

class CustomerClient extends Component {
    async addApplication(companyId) {
        const info = await Auth.currentUserInfo()
		const data = {
			companyId,
			phone: info.attributes['phone_number'],
			firstName: info.attributes['name'].split(' ')[0],
			lastName: info.attributes['name'].split(' ')[1],
			primaryGoal: info.attributes['custom:goal'],
			annualEarnings: info.attributes['custom:salary'],
			contribution: info.attributes['custom:contribution']
		}
		console.log(data)

		return API.post(apiPath, 'applications/add', {
			body: data
		})
	}

	async getApplication(companyId) {
        const info = await Auth.currentUserInfo()
		const data = {
			companyId,
			phone: info.attributes['phone_number']
		}
		console.log(data)

		return API.post(apiPath, 'applications/get', {
			body: data
		})
	}

	async updateLastActive(companyId) {
		const info = await Auth.currentUserInfo()
		const data = {
			companyId,
			phone: info.attributes['phone_number']
		}
		console.log(data)

		return API.post(apiPath, 'applications/update-last-active', {
			body: data
		})
	}

    updatePersonalDetails(companyId, phone, details) {
		const data = {
			companyId,
			phone,
            details
		}
		console.log(data)

		return API.post(apiPath, 'applications/update-personal-details', {
			body: data
		})
	}

    updatePensionDetails(companyId, phone, details) {
		const data = {
			companyId,
			phone,
            details
		}
		console.log(data)

		return API.post(apiPath, 'applications/update-pension-details', {
			body: data
		})
	}

	updateRiskDetails(companyId, phone, details) {
		const data = {
			companyId,
			phone,
            details
		}
		console.log(data)

		return API.post(apiPath, 'applications/update-risk-details', {
			body: data
		})
	}

	updateIdentity(companyId, phone, identity) {
		const data = {
			companyId,
			phone,
            identity
		}
		console.log(data)

		return API.post(apiPath, 'applications/update-identity', {
			body: data
		})
	}

	updatePayslip(companyId, phone, payslip) {
		const data = {
			companyId,
			phone,
            payslip
		}
		console.log(data)

		return API.post(apiPath, 'applications/update-payslip', {
			body: data
		})
	}

	async updateSubmissionStatus(companyId, submitted) {
        const info = await Auth.currentUserInfo()
		const data = {
			companyId,
			phone: info.attributes['phone_number'],
            submitted
		}
		console.log(data)

		return API.post(apiPath, 'applications/update-submitted', {
			body: data
		})
	}

	async addCallPreferenceNote(companyId, note) {
		const info = await Auth.currentUserInfo()
		const data = {
			companyId,
			phone: info.attributes['phone_number'],
            note
		}
		console.log(data)

		return API.post(apiPath, 'applications/add-call-preference', {
			body: data
		})
	}
} 

export default CustomerClient