import React, { useState, useContext } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Button from '@material-ui/core/Button'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import CustomerAPI from 'config/api/CustomerAPI'
import { onError } from 'config/lib/errorLib'

import DetailsContext from 'config/context/DetailsContext'
import { getSiteClass } from 'config/helpers/Helpers'

function ScheduleCallForm(props) {
    const api = new CustomerAPI()
    const companyDetails = useContext(DetailsContext)
    const companyId = companyDetails.companyId

    const [day, setDay] = useState('')
    const [time, setTime] = useState('')
    //const [disabled, setDisabled] = useState(props.note ? true : false)
    const [alert, setAlert] = useState(false)

    async function submitNote() {
        console.log('day: ', day)
        console.log('time: ', time)
        let callPreference = day + ' ' + time
        try {
            let app = await api.addCallPreferenceNote(companyId, callPreference)
            openSuccessAlert('Your call preference has been sent to your advisor')
            props.updateApplication(app)
        } catch(e) {
            onError(e)
        }
    }

    function openSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(false)}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

    return (
        <>
            {alert}
            <FormGroup>
                <FormLabel style={{textAlign: 'center'}}>Select a Day</FormLabel>
                <Grid container direction='row' justifyContent='center' alignItems='center' style={{marginTop: '1rem'}}>
                    <Grid item xs={6} lg={2}>
                        <FormGroup className={'select-day provider-form-group ' + (day === 'Monday' ? 'selected ' : '') + getSiteClass(companyId)}  onClick={() => setDay('Monday')}>
                            <p className={'primary-text ' + getSiteClass(companyId)}>Monday</p>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} lg={2}>
                        <FormGroup className={'select-day provider-form-group ' + (day === 'Tuesday' ? 'selected ' : '') + getSiteClass(companyId)}  onClick={() => setDay('Tuesday')}>
                            <p className={'primary-text ' + getSiteClass(companyId)}>Tuesday</p>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} lg={2}>
                        <FormGroup className={'select-day provider-form-group ' + (day === 'Wednesday' ? 'selected ' : '') + getSiteClass(companyId)}  onClick={() => setDay('Wednesday')}>
                            <p className={'primary-text ' + getSiteClass(companyId)}>Wednesday</p>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} lg={2}>
                        <FormGroup className={'select-day provider-form-group ' + (day === 'Thursday' ? 'selected ' : '') + getSiteClass(companyId)}  onClick={() => setDay('Thursday')}>
                            <p className={'primary-text ' + getSiteClass(companyId)}>Thursday</p>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} lg={2}>
                        <FormGroup className={'select-day provider-form-group ' + (day === 'Friday' ? 'selected ' : '') + getSiteClass(companyId)}  onClick={() => setDay('Friday')}>
                            <p className={'primary-text ' + getSiteClass(companyId)}>Friday</p>
                        </FormGroup>
                    </Grid>
                </Grid>
                {day.length > 0 &&
                    <>
                        <FormLabel style={{textAlign: 'center'}}>Select a Time</FormLabel>
                        <Grid container direction='row' justifyContent='center' alignItems='center' style={{marginTop: '1rem'}}>
                            <Grid item xs={6} lg={3}>
                                <FormGroup className={'select-day provider-form-group ' + (time === 'Morning' ? 'selected ' : '') + getSiteClass(companyId)}  onClick={() => setTime('Morning')}>
                                    <p className={'primary-text ' + getSiteClass(companyId)}>Morning</p>
                                </FormGroup>
                            </Grid>
                            <Grid item xs={6} lg={3}>
                                <FormGroup className={'select-day provider-form-group ' + (time === 'Evening' ? 'selected ' : '') + getSiteClass(companyId)}  onClick={() => setTime('Evening')}>
                                    <p className={'primary-text ' + getSiteClass(companyId)}>Evening</p>
                                </FormGroup>
                            </Grid>
                            {/*
                            <Grid item xs={6} lg={3}>
                                <FormGroup className={'select-day provider-form-group ' + (time === '12pm - 1pm' ? 'selected ' : '') + getSiteClass(companyId)}  onClick={() => setTime('12pm - 1pm')}>
                                    <p className={'primary-text ' + getSiteClass(companyId)}>12pm - 1pm</p>
                                </FormGroup>
                            </Grid>
                            <Grid item xs={6} lg={3}>
                                <FormGroup className={'select-day provider-form-group ' + (time === '1pm - 2pm' ? 'selected ' : '') + getSiteClass(companyId)}  onClick={() => setTime('1pm - 2pm')}>
                                    <p className={'primary-text ' + getSiteClass(companyId)}>1pm - 2pm</p>
                                </FormGroup>
                            </Grid>
                            <Grid item xs={6} lg={3}>
                                <FormGroup className={'select-day provider-form-group ' + (time === '2pm - 3pm' ? 'selected ' : '') + getSiteClass(companyId)}  onClick={() => setTime('2pm - 3pm')}>
                                    <p className={'primary-text ' + getSiteClass(companyId)}>2pm - 3pm</p>
                                </FormGroup>
                            </Grid>
                            <Grid item xs={6} lg={3}>
                                <FormGroup className={'select-day provider-form-group ' + (time === '3pm - 4pm' ? 'selected ' : '') + getSiteClass(companyId)}  onClick={() => setTime('3pm - 4pm')}>
                                    <p className={'primary-text ' + getSiteClass(companyId)}>3pm - 4pm</p>
                                </FormGroup>
                            </Grid>
                            <Grid item xs={6} lg={3}>
                                <FormGroup className={'select-day provider-form-group ' + (time === '4pm - 5pm' ? 'selected ' : '') + getSiteClass(companyId)}  onClick={() => setTime('4pm - 5pm')}>
                                    <p className={'primary-text ' + getSiteClass(companyId)}>4pm - 5pm</p>
                                </FormGroup>
                            </Grid>
                            <Grid item xs={6} lg={3}>
                                <FormGroup className={'select-day provider-form-group ' + (time === '5pm - 6pm' ? 'selected ' : '') + getSiteClass(companyId)}  onClick={() => setTime('5pm - 6pm')}>
                                    <p className={'primary-text ' + getSiteClass(companyId)}>5pm - 6pm</p>
                                </FormGroup>
                            </Grid>
                            <Grid item xs={6} lg={3}>
                                <FormGroup className={'select-day provider-form-group ' + (time === '6pm - 7pm' ? 'selected ' : '') + getSiteClass(companyId)}  onClick={() => setTime('6pm - 7pm')}>
                                    <p className={'primary-text ' + getSiteClass(companyId)}>6pm - 7pm</p>
                                </FormGroup>
                            </Grid>
                            <Grid item xs={6} lg={3}>
                                <FormGroup className={'select-day provider-form-group ' + (day === '7pm - 8pm' ? 'selected ' : '') + getSiteClass(companyId)}  onClick={() => setTime('7pm - 8pm')}>
                                    <p className={'primary-text ' + getSiteClass(companyId)}>7pm - 8pm</p>
                                </FormGroup>
                            </Grid>
                            */}
                        </Grid>
                    </>
                }
            </FormGroup>
            <Box style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {(day.length > 0 && time.length > 0) &&
                <Button
                    component={Box}
                    color='primary'
                    margin='auto!important'
                    variant='contained'
                    onClick={() => submitNote()}
                    //disabled={checkFormFields()}
                >
                    Submit
                </Button>
            }
            </Box>
        </>
    )
}

export default ScheduleCallForm