import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { getAge, getSiteClass } from 'config/helpers/Helpers'

function TaxReliefBox(props) {
    const age = props.dob ? getAge(props.dob) : 0
    console.log('age: ', age)
    const salary = props.salary ? parseFloat(props.salary) : 0
    console.log('salary: ', salary)
    const contribution = props.monthlyContribution ? parseFloat(props.monthlyContribution) * 12 : 0
    console.log('contribution: ', contribution)
    const maxContribution = getMaxContribution()
    console.log('max contribution: ', maxContribution)

    function getMaxContribution() {
        let max = 0
        if (age < 30) {
            max = 0.15
        }
        else if (age >= 30 && age < 40) {
            max = 0.2
        }
        else if (age >= 40 && age < 50) {
            max = 0.25
        }
        else if (age >= 50 && age < 55) {
            max = 0.3
        }
        else if (age >= 55 && age < 60) {
            max = 0.35
        }
        else if (age >= 60) {
            max = 0.4
        }
        return salary * max
    }

    function getTaxRelief(type) {
        let calcContribution = maxContribution
        if (type === 'current') {
            if (contribution < maxContribution) {
                calcContribution = contribution
            }
        }
        console.log('calc contribution: ', calcContribution)
        let postContribution = salary - calcContribution
        let higherRelief = 0
        let lowerRelief = 0

        if (postContribution > 42000) {
            higherRelief += (calcContribution * 0.4)
        }
        else if (postContribution <= 42000 && postContribution > 0) {
            let lower = 42000 - postContribution
            let higher = calcContribution - lower
            console.log('higher: ', higher)
            higherRelief = higher > 0 ? (higher * 0.4) : 0
            lowerRelief += (lower * 0.2)
        }
        console.log('higher relief: ', higherRelief)
        console.log('lower relief: ', lowerRelief)

        let relief = {
            higher: higherRelief.toFixed(2),
            lower: lowerRelief.toFixed(2),
            total: (higherRelief + lowerRelief).toFixed(2)
        }
        console.log('relief: ', relief)

        return relief
    }
    
    return (
        <Box className={'tax-relief-box ' + getSiteClass(props.companyId)}>
            <Grid container>
                <Grid item xs={12}>
                    <p>Based on your age ({age}) and salary (€{salary}) you can receive tax relief on your contributions up to €{maxContribution} per year (€{(maxContribution/12).toFixed(2)}/month). You will not be eligible to receive tax relief on any contributions made above this threshold.</p>
                </Grid>
                <Grid item xs={12}>
                    <p>Current monthly contribution: €{(contribution/12).toFixed(2)}</p>
                </Grid>  
                <Grid item xs={12}>
                    <p>Higher rate (40%) tax relief: €{getTaxRelief('current').higher}</p>
                </Grid>    
                <Grid item xs={12}>
                    <p>Lower rate (20%) tax relief: €{getTaxRelief('current').lower}</p>
                </Grid>   
                <Grid item xs={12}>
                    <p><strong>Total annual tax relief: €{getTaxRelief('current').total}</strong></p>
                    {/*<p>Annual tax relief with maximum contribution (€{(maxContribution/12).toFixed(2)}/month): €{getTaxRelief('max').total}</p>*/}
                </Grid>
            </Grid>
        </Box>
    )
}

export default TaxReliefBox