import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import Badge from '@material-ui/core/Badge'

import componentStylesBadge from 'assets/theme/components/badge.js'

const useStylesBadge = makeStyles(componentStylesBadge)

function ShowContentCard(props) {
    const classes = { ...useStylesBadge() }
    return (
        <Card>
            <CardHeader
                subheader={
                    <Grid
                        container
                        component={Box}
                        alignItems='center'
                        justifyContent='space-between'
                    >
                        <Grid item xs='auto'>
                            <Box
                                component={Typography}
                                variant='h3'
                                marginBottom='0!important'
                                display='flex'
                            >
                                <Box style={{marginRight: '1rem'}}>{props.title}</Box>
                                <Box>
                                    {props.complete ? (
                                        <Badge
                                            badgeContent={'Complete'}
                                            classes={{
                                                badge:
                                                classes.badgePositionRelative +
                                                " " +
                                                classes.badgeSuccess +
                                                " " +
                                                classes.badgeSizeSm,
                                            }}
                                            style={{fontSize: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                                        ></Badge>
                                    ) : (
                                        <Badge
                                            badgeContent={'Incomplete'}
                                            classes={{
                                                badge:
                                                classes.badgePositionRelative +
                                                " " +
                                                classes.badgeWarning +
                                                " " +
                                                classes.badgeSizeSm,
                                            }}
                                            style={{fontSize: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                                        ></Badge>
                                    )}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs='auto'>
                            <Box>
                                {props.showContent ? (
                                    <KeyboardArrowUp />
                                ) : (
                                    <KeyboardArrowDown />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                }
                onClick={() => props.setShowContent(!props.showContent)} 
                style={{cursor: 'pointer'}}
            >
            </CardHeader>
            
            {props.showContent &&
                <CardContent>
                    {props.content}
                </CardContent>
            }
        </Card>
    )
}

export default ShowContentCard