import React, { useContext } from 'react'
import { ScaleLoader } from 'react-spinners'
import { getSiteClass } from 'config/helpers/Helpers'
import DetailsContext from 'config/context/DetailsContext'

function Loader(props) {
	const companyDetails = useContext(DetailsContext)
    const companyId = companyDetails.companyId

	return (
		<div className={props.className ? 'loader ' + props.className : 'loader'}>
            <ScaleLoader
              	className={getSiteClass(companyId)}
              	loading={true} 
              	height={props.height ? props.height : 30}
            />
        </div>
    )
}

export default Loader