const isLocal = process.env.NODE_ENV === 'development'

const config =  {
    apiGatewayGuest: {
        REGION: 'eu-west-1',
        URL: isLocal ? 'https://d1o6hje65j.execute-api.eu-west-1.amazonaws.com/dev/' : 'https://4zr91zhdr9.execute-api.eu-west-1.amazonaws.com/prod/',
        PATH: 'guest-api',
    },
    apiGatewayCustomer: {
        REGION: 'eu-west-1',
        URL: isLocal ? 'https://q7pep5sux1.execute-api.eu-west-1.amazonaws.com/dev/' : 'https://pi7tmnyba9.execute-api.eu-west-1.amazonaws.com/prod/',
        PATH: 'customer-api',
    },
    apiGatewayBroker: {
        REGION: 'eu-west-1',
        URL: isLocal ? 'https://d9pyzfohx9.execute-api.eu-west-1.amazonaws.com/dev/' : 'https://rbf0gefn7d.execute-api.eu-west-1.amazonaws.com/prod/',
        PATH: 'broker-api',
    },
    // apiGatewayAdmin: {
    //     REGION: 'eu-west-1',
    //     URL: isLocal ? 'https://enkcgn9aqf.execute-api.eu-west-1.amazonaws.com/dev/' : 'https://qzuqqvt9nk.execute-api.eu-west-1.amazonaws.com/prod/',
    //     // PATH is just used to refer to api within the app when initialising and using amplify API
    //     PATH: 'admin-api',
    // },
    cognito: {
        REGION: 'eu-west-1',
        USER_POOL_ID: {
            al: 'eu-west-1_cQyKIl2DU',
            nph: 'eu-west-1_cQyKIl2DU'
        },
        APP_CLIENT_ID: {
            al: '1k4ubcf15cdldc4jkslm761nlj',
            nph: '1k4ubcf15cdldc4jkslm761nlj'
        },
        IDENTITY_POOL_ID: 'eu-west-1:11b95137-335a-44cb-b5ea-9440e3941bfb'
    },
    s3: {
        REGION: 'eu-west-1',
        BUCKET: {
            al: 'start-pension-pc-user-uploads',
            nph: 'start-pension-pc-user-uploads'
        }
    },
    SENTRY_DSN: 'https://8fa951ee651fbaa6af9af62f26f60eb9@o290751.ingest.sentry.io/4506179045556224',

}
export default config