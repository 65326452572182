import React, { useState, useContext } from 'react'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Button from '@material-ui/core/Button'
import { NotificationManager } from 'react-notifications'
import BrokerAPI from 'config/api/BrokerAPI'
import { onError } from 'config/lib/errorLib'

function AddNote(props) {
    const api = new BrokerAPI()
    const lead = props.lead

    const [note, setNote] = useState('')

    async function addNote() {
		console.log('add note...')
		try {
            let result = await api.addNote(lead.phone, note)
            console.log(result)

			setNote('')
			props.updateLead(result)
            props.openActivity()
            props.hide()
			NotificationManager.success('Note added')
		} catch(e) {
			onError(e)
		}
	}

	function checkNoteFields() {
		if (note.length > 0) {
			return false
		}
		else {
			return true
		}
	}
    
    return (
        <Grid container>
            <Grid item xs={12} lg={12}>
                <FormGroup>
                    <FormLabel>Note</FormLabel>
                    <FormControl
                        variant='filled'
                        component={Box}
                        width='100%'
                        marginBottom='1rem!important'
                    >
                        <OutlinedInput
                            fullWidth
                            type='text'
                            multiline
                            minRows={5}
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                        />
                    </FormControl>
                </FormGroup>
                <Box display='flex' justifyContent='flex-end'>
                    <Button
                        onClick={() => props.hide()}
                        color='primary'
                    >
                        Cancel
                    </Button>
                    <Button
                        component={Box}
                        onClick={() => addNote()}
                        color='primary'
                        style={{marginLeft: '1rem'}}
                        variant='contained'
                        disabled={checkNoteFields()}
                    >
                        Save
                    </Button>
                </Box>
            </Grid>
        </Grid>
    )
}

export default AddNote