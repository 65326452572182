import React, { useState, useContext } from 'react'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Loader from 'components/general/Loader'
import CustomerAPI from 'config/api/CustomerAPI'
import { onError } from 'config/lib/errorLib'
import { NotificationManager } from 'react-notifications'
import ReactDropzoneFile from 'components/general/ReactDropzoneFile'
import { v4 as uuidv4 } from 'uuid'
import { Auth, Storage } from 'aws-amplify'
import { getSiteClass } from 'config/helpers/Helpers'

import DetailsContext from 'config/context/DetailsContext'

function DocumentUpload(props) {
    const api = new CustomerAPI()
    const details = props.details
    const companyDetails = useContext(DetailsContext)
    const companyId = companyDetails.companyId
    const isBroker = props.broker

    const [identity, setIdentity] = useState(details.identity || null)
    const [payslip, setPayslip] = useState(details.payslip || null)
    const [loading, setLoading] = useState(false)

    async function uploadIdentity(file) {
        // upload to s3
        let identityKey = uuidv4()
        try {
            const info = await Auth.currentUserInfo()
            const phone = isBroker ? props.applicantPhone : info.attributes['phone_number']
            // upload to s3
            let identityPath = 'customers/' + phone + '/' + identityKey
            console.log('identityPath: ', identityPath)

            if (file) { 
                let identityUploaded = await Storage.put(identityPath, file, {
                    contentType: file.type,
                    // customPrefix: {
                    //    public: ''
                    // }
                })
                console.log('identityUploaded: ', identityUploaded)
                // add to dynamo
                let result = await api.updateIdentity(companyId, phone, identityKey)
                console.log('result: ', result)

                setIdentity(identityKey)

                props.updateApplication(result)
                NotificationManager.success('Uploaded')
            }
        } catch(e) {
            onError(e)
        }
    }

    async function uploadPayslip(file) {
        // upload to s3
        let payslipKey = uuidv4()
        try {
            const info = await Auth.currentUserInfo()
            const phone = isBroker ? props.applicantPhone : info.attributes['phone_number']
            // upload to s3
            let payslipPath = 'customers/' + phone + '/' + payslipKey
            console.log('payslipPath: ', payslipPath)

            if (file) { 
                let payslipUploaded = await Storage.put(payslipPath, file, {
                    contentType: file.type,
                    // customPrefix: {
                    //    public: ''
                    // }
                })
                console.log('payslipUploaded: ', payslipUploaded)
                // add to dynamo
                let result = await api.updatePayslip(companyId, phone, payslipKey)
                console.log('result: ', result)

                setPayslip(payslipKey)

                props.updateApplication(result)
                NotificationManager.success('Uploaded')
            }
        } catch(e) {
            onError(e)
        }
    }

    async function removeIdentity() {
        try {
            const info = await Auth.currentUserInfo()
            const phone = isBroker ? props.applicantPhone : info.attributes['phone_number']
            let result = await api.updateIdentity(companyId, phone, null)
            console.log('result: ', result)

            setIdentity(null)

            // Todo; delete object from s3

            props.updateApplication(result)
            NotificationManager.success('Removed')
        } catch(e) {
            onError(e)
        }
    }

    async function removePayslip() {
        try {
            const info = await Auth.currentUserInfo()
            const phone = isBroker ? props.applicantPhone : info.attributes['phone_number']
            let result = await api.updatePayslip(companyId, phone, null)
            console.log('result: ', result)

            setPayslip(null)

            // Todo; delete object from s3

            props.updateApplication(result)
            NotificationManager.success('Removed')
        } catch(e) {
            onError(e)
        }
    }

    async function getFile(key) {
        const info = await Auth.currentUserInfo()
        const phone = isBroker ? props.applicantPhone : info.attributes['phone_number']
        let url = await Storage.get('customers/' + phone + '/' + key)
        console.log('url: ', url)
        window.open(url)
    }

    function checkRequiredFields() {
        // if (firstName.length > 0 && lastName.length > 0) {
        //     return false
        // }
        // else {
        //     return true
        // }
        return false
    }

    async function submitApplication() {
        setLoading(true)
        props.submitApplication()
        setLoading(false)
    }
    
    return (
        <Grid container>
            <Grid item xs={12} lg={12} style={{marginBottom: '1rem'}}>
                <Grid container>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel style={{marginBottom: '1rem'}}>Passport/Driving License</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                {details.identity ? (
                                    <>
                                        <p className={'hover-text ' + getSiteClass(companyId)} onClick={() => getFile(identity)}>Uploaded (Click to view)</p>
                                        <p className='hover-text delete-text' onClick={() => removeIdentity()}>Delete</p>
                                    </>
                                ) : (
                                    <ReactDropzoneFile
                                        updateFile={(file) => uploadIdentity(file)}
                                        companyId={companyId}
                                    />
                                )}
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel style={{marginBottom: '1rem'}}>Payslip</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                {details.payslip ? (
                                    <>
                                        <p className={'hover-text ' + getSiteClass(companyId)} onClick={() => getFile(payslip)}>Uploaded (Click to view)</p>
                                        <p className='hover-text delete-text' onClick={() => removePayslip()}>Delete</p>
                                    </> 
                                ) : (
                                    <ReactDropzoneFile
                                        updateFile={(file) => uploadPayslip(file)}
                                        companyId={companyId}
                                    />
                                )}
                            </FormControl>
                        </FormGroup>
                    </Grid>
                </Grid>
            </Grid>
            
            <Grid item xs={12} lg={12}>     
                {!props.broker &&
                    <Grid container justifyContent='space-between'>
                        <Grid item>
                            <Button
                                component={Box}
                                color='secondary'
                                variant='contained'
                                size='medium'
                                onClick={() => props.updateStage(2)}
                            >
                                Back
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                component={Box}
                                color='primary'
                                variant='contained'
                                width={180}
                                disabled={checkRequiredFields() || loading}
                                onClick={() => submitApplication()}
                            >
                                {!loading ? (
                                    <>Submit Application</>
                                ) : (
                                    <Loader height={12} />
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Grid>
    )
}

export default DocumentUpload