import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import SelectAnswer from 'components/general/SelectAnswer'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function InfoModal(props) {
	const classes = {...useStyles(), ...useStylesDialog()}
  	const title = props.title

    function updateAnswer(answer) {
        props.updateAnswer(answer)
        props.close()
    }

	return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => props.close()}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'
		>
            {/*
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>
            */}
			
			<DialogContent style={{marginTop: '1rem'}}>
                <Grid container>
                    {title === 'Investment Knowledge' &&
                        <Grid item xs={12}>
                            <h3 style={{marginBottom: '2rem'}}>How would you describe your investment knowledge?</h3>
                            <SelectAnswer 
                                content='Expert - I have in-depth knowledge of how investments work'
                                selected={props.answer === 'Expert'}
                                updateAnswer={() => updateAnswer('Expert')}
                            />
                            <SelectAnswer 
                                content='High - I have a good understanding of the different types of investments and their risks'
                                selected={props.answer === 'High'}
                                updateAnswer={() => updateAnswer('High')}
                            />
                            <SelectAnswer 
                                content='Moderate - I have knowledge of the basic types of investments and their risks'
                                selected={props.answer === 'Moderate'}
                                updateAnswer={() => updateAnswer('Moderate')}
                            />
                            <SelectAnswer 
                                content='Little - I have little knowledge about investments and their risks'
                                selected={props.answer === 'Little'}
                                updateAnswer={() => updateAnswer('Little')}
                            />
                            <SelectAnswer 
                                content='None - I have no investment knowledge'
                                selected={props.answer === 'None'}
                                updateAnswer={() => updateAnswer('None')}
                            />
                        </Grid>
                    }
                    {title === 'Savings' &&
                        <Grid item xs={12}>
                            <h3 style={{marginBottom: '2rem'}}>Do you have other savings available to you, should you encounter a financial emergency?</h3>
                            <SelectAnswer 
                                content='No - I have no other savings I can use in the event of an emergency'
                                selected={props.answer === 'No'}
                                updateAnswer={() => updateAnswer('No')}
                            />
                            <SelectAnswer 
                                content='Some - I have some other savings, but nothing earmarked for a financial emergency'
                                selected={props.answer === 'Some'}
                                updateAnswer={() => updateAnswer('Some')}
                            />
                            <SelectAnswer 
                                content='Yes - I have specific savings that I can use in an emergency'
                                selected={props.answer === 'Yes'}
                                updateAnswer={() => updateAnswer('Yes')}
                            />
                        </Grid>
                    }
                    {title === 'Investment Impact' &&
                        <Grid item xs={12}>
                            <h3 style={{marginBottom: '2rem'}}>If this investment were to perform poorly, what impact would this have on your financial well-being?</h3>
                            <SelectAnswer 
                                content='Big - It would have a big impact. This investment is required for a specific purpose.'
                                selected={props.answer === 'Big'}
                                updateAnswer={() => updateAnswer('Big')}
                            />
                            <SelectAnswer 
                                content='Some - It would have some impact. I would need to make some adjustments to my future plans.'
                                selected={props.answer === 'Some'}
                                updateAnswer={() => updateAnswer('Some')}
                            />
                            <SelectAnswer 
                                content='None - It would have no impact, as I have other savings.'
                                selected={props.answer === 'None'}
                                updateAnswer={() => updateAnswer('None')}
                            />
                        </Grid>
                    }
                </Grid>
			</DialogContent>
            
			<DialogActions>
				<Button
					onClick={() => props.close()}
					color='primary'
				>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	)
}