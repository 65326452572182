import React, { useState, useContext } from 'react'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Loader from 'components/general/Loader'
import SelectProvider from 'components/general/SelectProvider'
import TaxReliefBox from 'components/customer/TaxReliefBox'
import CustomerAPI from 'config/api/CustomerAPI'
import { onError } from 'config/lib/errorLib'
import { NotificationManager } from 'react-notifications'
import { 
    pensionTypeOptions,
    pensionValueOptions, 
    pensionLocationOptions, 
    retirementPlanningOptions 
} from 'config/constants/Constants'
import { Auth } from 'aws-amplify'

import DetailsContext from 'config/context/DetailsContext'

function RetirementPlanningDetails(props) {
    const api = new CustomerAPI()
    const details = props.details
    const companyDetails = useContext(DetailsContext)
    const companyId = companyDetails.companyId
    const isBroker = props.broker

    const [retirementAge, setRetirementAge] = useState(details.retirementAge || '65')
    const [pensionType, setPensionType] = useState(details.pensionType || '')
    const [value, setValue] = useState(details.value || '')
    const [currentLocation, setCurrentLocation] = useState(details.currentLocation || '')
    const [primaryGoal, setPrimaryGoal] = useState(details.primaryGoal || '')
    const [loading, setLoading] = useState(false)
    
    async function updateDetails() {
        setLoading(true)
        try {
            const info = await Auth.currentUserInfo()
            const phone = isBroker ? props.applicantPhone : info.attributes['phone_number']
            let details = {
                retirementAge,
                pensionType,
                value,
                currentLocation,
                primaryGoal
            }
            let result = await api.updatePensionDetails(companyId, phone, details)

            props.updateApplication(result)
            if (props.broker) {
                props.closeContent()
            }
            else {
                props.updateStage(2)
            }
            NotificationManager.success('Details saved')
        } catch(e) {
            onError(e)
        }
        setLoading(false)
    }

    function checkRequiredFields() {
        // if (firstName.length > 0 && lastName.length > 0) {
        //     return false
        // }
        // else {
        //     return true
        // }
        return false
    }

    function updatePensionDetails(field, value) {
        let details = {
            retirementAge,
            pensionType,
            value,
            currentLocation,
            primaryGoal
        }
        details[field] = value
        if (isBroker) {
            props.updatePensionDetails(details)
        }
    }
    
    return (
        <Grid container>
            <Grid item xs={12} lg={12} style={{marginBottom: '1rem'}}>
                <Grid container>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>Retirement Age</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <OutlinedInput
                                    fullWidth
                                    type='text'
                                    value={retirementAge}
                                    onChange={(e) => { setRetirementAge(e.target.value); updatePensionDetails('retirementAge', e.target.value); }}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>Pension Type</FormLabel>
                            <FormControl variant='outlined' fullWidth>
                                <Select
                                    multiple={false}
                                    IconComponent={KeyboardArrowDown}
                                    value={pensionType}
                                    onChange={(e) => { setPensionType(e.target.value); updatePensionDetails('pensionType', e.target.value); }}
                                >
                                    {pensionTypeOptions.map((option, i) => {
                                        return (<MenuItem value={option.value} key={i}>{option.label}</MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>Current Value (€)</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <OutlinedInput
                                    fullWidth
                                    type='text'
                                    value={value}
                                    onChange={(e) => { setValue(e.target.value); updatePensionDetails('value', e.target.value); }}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>Current Location</FormLabel>
                            <FormControl variant='outlined' fullWidth>
                                <Select
                                    multiple={false}
                                    IconComponent={KeyboardArrowDown}
                                    value={currentLocation}
                                    onChange={(e) => { setCurrentLocation(e.target.value); updatePensionDetails('currentLocation', e.target.value); }}
                                >
                                    {pensionLocationOptions.map((option, i) => {
                                        return (<MenuItem value={option.value} key={i}>{option.label}</MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>Primary Goal</FormLabel>
                            <FormControl variant='outlined' fullWidth>
                                <Select
                                    multiple={false}
                                    IconComponent={KeyboardArrowDown}
                                    value={primaryGoal}
                                    onChange={(e) => { setPrimaryGoal(e.target.value); updatePensionDetails('primaryGoal', e.target.value); }}
                                >
                                    {retirementPlanningOptions.map((option, i) => {
                                        return (<MenuItem value={option.value} key={i}>{option.label}</MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    {/*
                    <Grid item xs={12} lg={12}>
                        <FormGroup>
                            <FormLabel>Select your preferred pension provider</FormLabel>
                            <SelectProvider 
                                provider={preferredProvider}
                                updateProvider={(provider) => { setPreferredProvider(provider); updatePensionDetails('preferredProvider', provider); }}
                            />
                        </FormGroup>
                    </Grid>
                    */}
                </Grid>
            </Grid>

            <Grid item xs={12} lg={12}>        
                <Grid container justifyContent='space-between'>
                    <Grid item>
                        {!props.broker &&
                            <Button
                                component={Box}
                                color='secondary'
                                variant='contained'
                                size='medium'
                                onClick={() => props.updateStage(0)}
                            >
                                Back
                            </Button>
                        }
                    </Grid>
                    <Grid item>
                        <Button
                            component={Box}
                            color='primary'
                            variant='contained'
                            width={180}
                            disabled={checkRequiredFields() || loading}
                            onClick={() => updateDetails()}
                        >
                            {!loading ? (
                                <>{!props.broker ? (
                                    <>Save & Continue</>
                                ) : (
                                    <>Update</>
                                )}</>
                            ) : (
                                <Loader height={12} />
                            )}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default RetirementPlanningDetails