export const pipelineStages = {
    new: 'New Application',
    appSubmitted: 'Application Submitted',
    discoveryCall: 'Discovery Call',
    meetingScheduled: 'Meeting Scheduled',
    meetingComplete: 'Meeting Complete',
    pensionSubmitted: 'Pension Submitted',
    won: 'Completed',
    lost: 'Lost'
}

export const pipelineStageColors = {
    new: 'grey',
    appSubmitted: '#ff7621',
    discoveryCall: '#d32f7d',
    meetingScheduled: '#f3cf26',
    meetingComplete: '#2f7dd3',
    awaitingSignature: '#d5297f',
    pensionSubmitted: '#761cd4',
    won: '#21c47b',
    lost: 'red'
}

export const providerOptions = [
    {value: 'Zurich', label: 'Zurich'},
    {value: 'Irish Life', label: 'Irish Life'},
    {value: 'Aviva', label: 'Aviva'},
    {value: 'Standard Life', label: 'Standard Life'},
    {value: 'New Ireland', label: 'New Ireland'}
]

export const primaryGoalOptions = [
    {value: 'To maintain my current income', label: 'To maintain my current income'},
    {value: 'To increase my income', label: 'To increase my income'},
    {value: 'To have a slightly lower income', label: 'To have a slightly lower income'},
    {value: 'I am not sure', label: 'I am not sure'}
]

export const primaryGoalOptionsPc = [
    {value: 'To maintain my standard of living', label: 'To maintain my standard of living'},
    {value: 'To provide an income in retirement', label: 'To provide an income in retirement'},
    {value: 'To provide a tax free lump sum at retirement', label: 'To provide a tax free lump sum at retirement'},
    {value: 'I am not sure / I need some advice', label: 'I am not sure / I need some advice'}
]

export const genderOptions = [
    {value: 'Male', label: 'Male'},
    {value: 'Female', label: 'Female'}
]

export const maritalStatusOptions = [
    {value: 'married', label: 'Married'},
    {value: 'single', label: 'Single'},
    {value: 'divorced', label: 'Divorced'},
    {value: 'civil-partner', label: 'Civil Partnership'},
    {value: 'separated', label: 'Separated'},
]

export const smokingStatusOptions = [
    {value: 'Smoker', label: 'Smoker'},
    {value: 'Non Smoker', label: 'Non Smoker'}
]

export const employmentStatusOptions = [
    {value: 'Employee', label: 'Employee'},
    {value: 'Company Director', label: 'Company Director'},
    {value: 'Self Employed', label: 'Self Employed'},
    {value: 'Unemployed', label: 'Unemployed'}
]

export const monthOptions = [
    {value: 'January', label: 'January'},
    {value: 'February', label: 'February'},
    {value: 'March', label: 'March'},
    {value: 'April', label: 'April'},
    {value: 'May', label: 'May'},
    {value: 'June', label: 'June'},
    {value: 'July', label: 'July'},
    {value: 'August', label: 'August'},
    {value: 'September', label: 'September'},
    {value: 'October', label: 'October'},
    {value: 'November', label: 'November'},
    {value: 'December', label: 'December'},
]

export const countyOptions = [
    {value: 'All', label: 'All'},
    //{value: 'Antrim', label: 'Antrim'},
    //{value: 'Armagh', label: 'Armagh'},
    {value: 'Carlow', label: 'Carlow'},
    {value: 'Cavan', label: 'Cavan'},
    {value: 'Clare', label: 'Clare'},
    {value: 'Cork', label: 'Cork'},
    //{value: 'Derry', label: 'Derry'},
    {value: 'Donegal', label: 'Donegal'},
    //{value: 'Down', label: 'Down'},
    {value: 'Dublin', label: 'Dublin'},
    //{value: 'Fermanagh', label: 'Fermanagh'},
    {value: 'Galway', label: 'Galway'},
    {value: 'Kerry', label: 'Kerry'},
    {value: 'Kildare', label: 'Kildare'},
    {value: 'Kilkenny', label: 'Kilkenny'},
    {value: 'Laois', label: 'Laois'},
    {value: 'Leitrim', label: 'Leitrim'},
    {value: 'Limerick', label: 'Limerick'},
    {value: 'Longford', label: 'Longford'},
    {value: 'Louth', label: 'Louth'},
    {value: 'Mayo', label: 'Mayo'},
    {value: 'Meath', label: 'Meath'},
    {value: 'Monaghan', label: 'Monaghan'},
    {value: 'Offaly', label: 'Offaly'},
    {value: 'Roscommon', label: 'Roscommon'},
    {value: 'Sligo', label: 'Sligo'},
    {value: 'Tipperary', label: 'Tipperary'},
    //{value: 'Tyrone', label: 'Tyrone'},
    {value: 'Waterford', label: 'Waterford'},
    {value: 'Westmeath', label: 'Westmeath'},
    {value: 'Wexford', label: 'Wexford'},
    {value: 'Wicklow', label: 'Wicklow'}
]

export const countryOptions = [
    {value: 'Ireland', label: 'Ireland'}
]

export const pensionTypeOptions = [
    {value: 'Defined Contribution (DC)', label: 'Defined Contribution (DC)'},
    {value: 'Define Benefit (DB)', label: 'Define Benefit (DB)'},
    {value: 'I’m not sure', label: 'I’m not sure'}
]

export const pensionValueOptions = [
    {value: 'Under €30,000', label: 'Under €30,000'},
    {value: '€30,000 – €60,000', label: '€30,000 – €60,000'},
    {value: '€70,000 – €100,000', label: '€70,000 – €100,000'},
    {value: '€100,000+', label: '€100,000+'}
]

export const pensionLocationOptions = [
    {value: 'Old Employer', label: 'Old Employer'},
    {value: 'Current Employer', label: 'Current Employer'},
    {value: 'Personal Retirement Bond', label: 'Personal Retirement Bond'},
    {value: 'PRSA', label: 'PRSA'},
    {value: 'Personal Pension', label: 'Personal Pension'},
    {value: 'I’m not sure', label: 'I’m not sure'}
]

export const pensionTransferOptions = [
    {value: 'Access a tax-free lump sum', label: 'Access a tax-free lump sum'},
    {value: 'Invest into a higher growth pension fund', label: 'Invest into a higher growth pension fund'},
    {value: 'Transfer from an employer scheme into your name', label: 'Transfer from an employer scheme into your name'},
    {value: 'Other', label: 'Other'}
]

export const retirementPlanningOptions = [
    {value: 'Retiring in 1 - 6 months', label: 'Retiring in 1 - 6 months'},
    {value: 'Access a tax-free lump sum', label: 'Access a tax-free lump sum'},
    {value: 'Investment advice', label: 'Investment advice'},
    {value: 'Other', label: 'Other'}
]

export const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$') // standard email format
export const validPhone = new RegExp('^[0-9+]{9,15}$') // string between 9 and 15 characters long with numbers or + symbol
export const validDob = new RegExp('^(0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2}$') // Date (MM/DD/YYYY)/(MM-DD-YYYY)/(MM.DD.YYYY)/(MM DD YYYY)