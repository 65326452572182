import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import CardContent from '@material-ui/core/CardContent'
import FilledInput from '@material-ui/core/FilledInput'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import PhoneInput from 'components/general/PhoneInput'
import Loader from 'components/general/Loader'
import { makeStyles } from '@material-ui/core/styles'
import { NotificationManager } from 'react-notifications'
import { getSiteClass } from 'config/helpers/Helpers'
import { getPrimaryGoalOptions, getPrivacyPolicy } from 'config/helpers/Helpers'

import componentStyles from 'assets/theme/views/auth/login.js'
import componentStylesButtons from 'assets/theme/components/button.js'

const useStyles = makeStyles(componentStyles)
const useStylesButtons = makeStyles(componentStylesButtons)

function StartPensionForm(props) {
    const classes = { ...useStyles(), ...useStylesButtons() }
    const companyId = props.companyId

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [dob, setDob] = useState('')
    const [salary, setSalary] = useState('')
    const [contribution, setContribution] = useState('')
    const [goal, setGoal] = useState('')
    const [termsConditions, setTermsConditions] = useState(false)

    async function register(e) {
        e.preventDefault()
        if (firstName.length > 0 && lastName.length > 0 && phone.length > 0 && email.length > 0 && dob.length > 0 && (salary.length > 0 && parseFloat(salary) >= 30000) && (contribution.length > 0 && parseFloat(contribution) >= 150) && goal.length > 0 && termsConditions === true) {
            let type = 'Start Pension'
            let personalDetails = {
                firstName,
                lastName,
                phone,
                email,
                dob
            }
            let pensionDetails = {
                goal,
                salary,
                contribution
            }
            console.log('personal details: ', personalDetails)
            console.log('pension details: ', pensionDetails)
            props.register(type, personalDetails, pensionDetails)
            //props.updateStage('phoneInput')
        }
        else if (!firstName.length > 0) {
            NotificationManager.error('Please enter your first name')
        }
        else if (!lastName.length > 0) {
            NotificationManager.error('Please enter your last name')
        }
        else if (!phone.length > 0) {
            NotificationManager.error('Please enter your phone')
        }
        else if (!email.length > 0) {
            NotificationManager.error('Please enter your email')
        }
        else if (!dob.length > 0) {
            NotificationManager.error('Please enter your date of birth')
        }
        else if (!salary.length > 0) {
            NotificationManager.error('Please enter your salary')
        }
        else if (!contribution.length > 0) {
            NotificationManager.error('Please enter your monthly contribution')
        }
        else if (!goal.length > 0) {
            NotificationManager.error('Please select your goal in retirement from the dropdown')
        }
        else if (!termsConditions) {
            NotificationManager.error('Please accept the terms & conditions')
        }
        else {
            NotificationManager.error('Please complete all fields')
        }
    }

    function updateDob(val) {
        let updated = val.replace(/^(\d{2})(\d{2})/, '$1/$2/')
        setDob(updated)
    }
    
	return (
		<form onSubmit={(e) => register(e)}>
            <Box component={Grid} container justifyContent='center'>
                <Grid item xs={12} lg={6} md={7}>
                    <Card classes={{ root: classes.cardRoot }} style={{backgroundColor: '#fff'}}>
                        {/*<Button size='small' variant='contained' color='secondary' style={{width: '60px', margin: '0.5rem'}} onClick={() => setStage('select')}>Back</Button>*/}
                        <CardContent classes={{ root: classes.cardContent }}>
                            <Grid container style={{marginBottom: '1.5rem'}}>
                                <Grid item xs={6}>
                                    <FormGroup>
                                        <FormLabel>First Name</FormLabel>
                                        <FormControl
                                            variant='filled'
                                            component={Box}
                                            width='100%'
                                        >
                                            <FilledInput
                                                autoComplete='off'
                                                type='text'
                                                placeholder='John'
                                                value={firstName}
                                                onChange={(e) => setFirstName(e.target.value)}
                                            />
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormGroup>
                                        <FormLabel>Last Name</FormLabel>
                                        <FormControl
                                            variant='filled'
                                            component={Box}
                                            width='100%'
                                        >
                                            <FilledInput
                                                autoComplete='off'
                                                type='text'
                                                placeholder='Smith'
                                                value={lastName}
                                                onChange={(e) => setLastName(e.target.value)}
                                            />
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            <Grid container style={{marginBottom: '1.5rem'}}>
                                <Grid item xs={12}>
                                    <FormGroup>
                                        <FormLabel>Phone Number</FormLabel>
                                        <FormControl
                                            variant='filled'
                                            component={Box}
                                            width='100%'
                                        >
                                            <PhoneInput
                                                phone={phone}
                                                updatePhone={(phone) => setPhone(phone)}
                                            />
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            <Grid container style={{marginBottom: '1.5rem'}}>
                                <Grid item xs={6}>
                                    <FormGroup>
                                        <FormLabel>Email</FormLabel>
                                        <FormControl
                                            variant='filled'
                                            component={Box}
                                            width='100%'
                                        >
                                            <FilledInput
                                                autoComplete='off'
                                                type='text'
                                                placeholder='john.smith@gmail.com'
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormGroup>
                                        <FormLabel>Date of Birth</FormLabel>
                                        <FormControl
                                            variant='filled'
                                            component={Box}
                                            width='100%'
                                        >
                                            <FilledInput
                                                autoComplete='off'
                                                type='text'
                                                placeholder='DD / MM / YYYY'
                                                value={dob}
                                                onChange={(e) => updateDob(e.target.value)}
                                            />
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            <Grid container style={{marginBottom: '1.5rem'}}>
                                <Grid item xs={12}>
                                    <FormGroup>
                                        <FormLabel>What is your current annual salary?</FormLabel>
                                        <FormControl
                                            variant='filled'
                                            component={Box}
                                            width='100%'
                                        >
                                            <FilledInput
                                                autoComplete='off'
                                                type='text'
                                                placeholder='45000'
                                                value={salary}
                                                //disabled={true}
                                                onChange={(e) => setSalary(e.target.value)}
                                            />
                                        </FormControl>
                                        {(salary && parseFloat(salary) < 30000) &&
                                            <FormLabel className='reg-subtext red-text'>The minimum salary to apply via the online application portal is €30,000</FormLabel>
                                        }
                                    </FormGroup>
                                </Grid>
                            </Grid>

                            <Grid container style={{marginBottom: '1.5rem'}}>
                                <Grid item xs={12}>
                                    <FormGroup>
                                        <FormLabel>How much do you want to contribute to your pension each month?</FormLabel>
                                        <FormControl
                                            variant='filled'
                                            component={Box}
                                            width='100%'
                                        >
                                            <FilledInput
                                                autoComplete='off'
                                                type='text'
                                                placeholder='300'
                                                value={contribution}
                                                onChange={(e) => setContribution(e.target.value)}
                                            />
                                        </FormControl>
                                        <FormLabel className='reg-subtext'>Note: You will get tax relief of 20-40% on your pension contributions</FormLabel>
                                        {(contribution && parseFloat(contribution) < 150) &&
                                            <FormLabel className='reg-subtext red-text'>The minimum monthly contribution to apply via the online application portal is €150</FormLabel>
                                        }
                                    </FormGroup>
                                </Grid>
                            </Grid>

                            <Grid container style={{marginBottom: '1.5rem'}}>
                                <Grid item xs={12}>
                                    <FormGroup>
                                        <FormLabel>What is your goal in retirement?</FormLabel>
                                        <FormControl
                                            variant='filled'
                                            component={Box}
                                            width='100%'
                                        >
                                            <Select
                                                multiple={false}
                                                IconComponent={KeyboardArrowDown}
                                                value={goal}
                                                onChange={(e) => setGoal(e.target.value)}
                                            >
                                                {getPrimaryGoalOptions().map((option, i) => {
                                                    return (<MenuItem value={option.value} key={i}>{option.label}</MenuItem>)
                                                })}
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            
                            <Grid container style={{marginBottom: '1.5rem'}}>
                                <Grid item xs={12}>
                                    <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start'>
                                        <Box sty>
                                            <Checkbox
                                                color='primary' 
                                                checked={termsConditions} 
                                                onChange={() => setTermsConditions(!termsConditions)}
                                            />
                                            <FormLabel>I agree to the terms and conditions & <a class={getSiteClass(companyId)} href={getPrivacyPolicy(companyId)}>privacy policy</a></FormLabel>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            
                            <Box
                                textAlign='center'
                                marginTop='1.5rem'
                                marginBottom='1.5rem'
                            >
                                <Button
                                    variant='contained'
                                    color='primary'
                                    //onClick={() => signIn()}
                                    type='submit'
                                    disabled={props.loading}
                                >
                                    {!props.loading ? (
                                        <>Start Application</>
                                    ) : (
                                        <Loader height={12} />
                                    )}
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                    
                    <Grid container component={Box} marginTop='1rem'>
                        <Grid item xs={12} component={Box} textAlign='center'>
                            {/*
                            <a
                                href='#'
                                onClick={(e) => openForgotPasswordModal(e)}
                                className={classes.footerLinks}
                                style={{color: '#1a73e8', opacity: '50%'}}
                            >
                                Forgot password
                            </a>
                            */}
                            <a
                                href='#'
                                onClick={(e) => props.updateStage('login')}
                                className={getSiteClass(companyId) + ' ' + classes.footerLinks}
                                style={{opacity: '70%'}}
                            >
                                Already have a pension application? Sign in
                            </a>
                        </Grid>
                        
                        <Grid item xs={6} component={Box} textAlign='right'>
                            {/*
                            <a
                                href='/register'
                                //onClick={(e) => e.preventDefault()}
                                className={classes.footerLinks}
                                style={{color: '#45129e', opacity: '50%'}}
                            >
                                Create new account
                            </a>
                            */}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </form>
    )
}

export default StartPensionForm