import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'

import componentStylesBadge from 'assets/theme/components/badge.js'

const useStylesBadge = makeStyles(componentStylesBadge)

function SelectAnswer(props) {
    const classes = { ...useStylesBadge() }
    return (
        <Card className='select-answer' onClick={() => props.updateAnswer()}>
            <CardContent>
                <Grid container direction='row' alignItems='center' justifyContent='center'>
                    <Grid xs={2}>
                        <Checkbox color='primary' checked={props.selected}  onChange={() => props.updateAnswer()}/>
                    </Grid>
                    <Grid xs={10}>
                        <p className='normal-p' style={{marginBottom: '0'}}>{props.content}</p>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default SelectAnswer