import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Loader from 'components/general/Loader'
import PersonalDetails from 'components/customer/Details/PersonalDetails'
import PensionDetails from 'components/customer/Details/PensionDetails'
import RiskDetails from 'components/customer/Details/RiskDetails'
import DocumentUpload from 'components/customer/Details/DocumentUpload'

import componentStyles from 'assets/theme/components/cards/cards/card-button.js'
import componentStylesBadge from 'assets/theme/components/badge.js'

const useStyles = makeStyles(componentStyles)
const useStylesBadge = makeStyles(componentStylesBadge)

function ApplicationCard(props) {
    const classes = { ...useStyles(), ...useStylesBadge() }
    //console.log('details: ', details)
    const stage = props.stage
    const application = props.application
    const loading = props.loading
    
    return (
        <Card
            classes={{
                root: classes.cardRoot,
            }}
        >
            <CardHeader
                subheader={
                    <Grid
                        container
                        component={Box}
                        alignItems='center'
                        justifyContent='space-between'
                    >
                        <Grid item xs='auto'>
                            <Box
                                component={Typography}
                                variant='h2'
                                marginBottom='0!important'
                            >
                                {stage === 0 &&
                                    <>Step 1: Personal Details</>
                                }
                                {stage === 1 &&
                                    <>Step 2: Pension Details</>
                                }
                                {stage === 2 &&
                                    <>Step 3: Risk Profile</>
                                }
                                {stage === 3 &&
                                    <>Step 4: Document Upload</>
                                }
                            </Box>
                            {(stage === 1 || stage === 2) &&
                                <Box
                                    component='p'
                                    fontSize='.875rem'
                                    marginBottom='0'
                                    marginTop='0.5rem'
                                    lineHeight='1.7'
                                    fontWeight='300'
                                >
                                    Skip any questions you are unsure about
                                </Box>
                            }
                            {stage === 3 &&
                                <Box
                                    component='p'
                                    fontSize='.875rem'
                                    marginBottom='0'
                                    marginTop='0.5rem'
                                    lineHeight='1.7'
                                    fontWeight='300'
                                >
                                    Skip any documents you do not have to hand
                                </Box>
                            }
                        </Grid>
                    </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
            >
            </CardHeader>
            
            <CardContent>
                {!loading ? (
                    <Grid container>
                        <Grid item xs={12} lg={12}>  
                            {stage === 0 &&
                                <PersonalDetails 
                                    phone={application.phone}
                                    details={application.personalDetails}
                                    updateApplication={(application) => props.updateApplication(application)}
                                    updateStage={(stage) => props.setStage(stage)}
                                />
                            }
                            {stage === 1 &&
                                <PensionDetails 
                                    details={application.pensionDetails}
                                    dob={application.personalDetails.dob}
                                    salary={application.personalDetails.annualEarnings}
                                    updateApplication={(application) => props.updateApplication(application)}
                                    updateStage={(stage) => props.setStage(stage)}
                                />
                            }
                            {stage === 2 &&
                                <RiskDetails 
                                    details={application.riskDetails}
                                    dob={application.personalDetails.dob}
                                    retirementAge={application.pensionDetails.retirementAge}
                                    updateApplication={(application) => props.updateApplication(application)}
                                    submitApplication={() => props.submitApplication()}
                                    updateStage={(stage) => props.setStage(stage)}
                                />
                            }
                            {stage === 3 &&
                                <DocumentUpload 
                                    details={application.documents}
                                    updateApplication={(application) => props.updateApplication(application)}
                                    submitApplication={() => props.submitApplication()}
                                    updateStage={(stage) => props.setStage(stage)}
                                />
                            }
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                                <Loader />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </CardContent>
        </Card>
    )
}

export default ApplicationCard