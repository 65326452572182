import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Grid from '@material-ui/core/Grid'
import BrokerAPI from 'config/api/BrokerAPI'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { providerOptions } from 'config/constants/Constants'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function ConfirmContributionModal(props) {
	const classes = {...useStyles(), ...useStylesDialog()}
    const api = new BrokerAPI()

    const application = props.application

    const [providerName, setProviderName] = useState(application.providerDetails.providerName || '')
    const [fundName, setFundName] = useState(application.providerDetails.providerName || '')
  	const [contribution, setContribution] = useState(application.pensionDetails.contribution || '')

  	async function confirmContribution() {
        let newApplication = {...application}
        newApplication.providerDetails.providerName = providerName
        newApplication.providerDetails.fundName = fundName
        newApplication.pensionDetails.contribution = contribution
        props.confirmContribution(newApplication)
  	}

  	function checkContribution() {
  		if (contribution.length > 0) {
  			return false
  		}
  		else {
  			return true
  		}
  	}

	return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => props.close()}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'
            maxWidth='sm'
		>
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					Confirm Monthly Contribution
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>
			
			<DialogContent>
                <Grid container>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>Monthly Contribution (€)</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                                autoFocus
                                //marginBottom='1rem!important'
                            >
                                <OutlinedInput
                                    fullWidth
                                    type='number'
                                    value={contribution}
                                    onChange={(e) => setContribution(e.target.value)}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>Provider Name</FormLabel>
                            <FormControl variant='outlined' fullWidth>
                                <Select
                                    multiple={false}
                                    IconComponent={KeyboardArrowDown}
                                    value={providerName}
                                    onChange={(e) => setProviderName(e.target.value)}
                                >
                                    {providerOptions.map((option, i) => {
                                        return (<MenuItem value={option.value} key={i}>{option.label}</MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>Fund Name</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <OutlinedInput
                                    fullWidth
                                    type='text'
                                    value={fundName}
                                    onChange={(e) => setFundName(e.target.value)}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                </Grid>
			</DialogContent>
			
			<DialogActions>
				<Button
					onClick={() => props.close()}
					color='primary'
				>
					Cancel
				</Button>
				
				<Button
					component={Box}
					onClick={() => confirmContribution()}
					color='primary'
					marginLeft='auto!important'
					variant='contained'
					disabled={checkContribution()}
				>
					Submit
				</Button>
			</DialogActions>
		</Dialog>
	)
}