import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge'
import Box from '@material-ui/core/Box'

import componentStylesBadge from 'assets/theme/components/badge.js'

const useStylesBadge = makeStyles(componentStylesBadge)

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        "& .MuiStepIcon-active": { color: getSiteColor() },
        "& .MuiStepLabel-active": { color: getSiteColor() }
    },
    button: {
        marginRight: theme.spacing(1),
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const stageMap = {
    'Personal Details': 'personalDetails',
    'Pension Details': 'pensionDetails',
    'Risk Profile': 'riskDetails'
    //'Document Upload': 'documents'
}

function getSteps() {
    return ['Personal Details', 'Pension Details', 'Risk Profile'];
}

function getSiteColor(companyId) {
    let color = null
    if (companyId === 'qf') {
        color = '#2257bb'
    }
    if (companyId === 'nph') {
        color = '#015f63'
    }
    if (companyId === 'bq') {
        color = '#015f63'
    }
    if (companyId === 'al') {
        color = '#076b9d'
    }
    return color
}

export default function HorizontalNonLinearAlternativeLabelStepper(props) {
    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
            "& .MuiStepIcon-active": { color: getSiteColor(props.companyId) },
            "& .MuiStepLabel-active": { color: getSiteColor(props.companyId) }
        },
        button: {
            marginRight: theme.spacing(1),
        },
        backButton: {
            marginRight: theme.spacing(1),
        },
        completed: {
            display: 'inline-block',
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    }));
    const classes = { ...useStyles(), ...useStylesBadge() }
    //const [activeStep, setActiveStep] = React.useState(0);
    const activeStep = props.stage;

    const [completed, setCompleted] = React.useState(new Set());
    const [skipped, setSkipped] = React.useState(new Set());
    const steps = getSteps();

    const totalSteps = () => {
        return getSteps().length;
    };

    const isStepOptional = (step) => {
        return false;
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        //setActiveStep((prevActiveStep) => prevActiveStep + 1);
        props.setStage((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const skippedSteps = () => {
        return skipped.size;
    };

    const completedSteps = () => {
        return completed.size;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps() - skippedSteps();
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
            ? // It's the last step, but not all steps have been completed
            // find the first step that has been completed
            steps.findIndex((step, i) => !completed.has(i))
            : activeStep + 1;

        //setActiveStep(newActiveStep);
        props.setStage(newActiveStep);
    };

    const handleBack = () => {
        //setActiveStep((prevActiveStep) => prevActiveStep - 1);
        props.setStage((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        //setActiveStep(step);
        props.setStage(step);
    };

    const handleComplete = () => {
        const newCompleted = new Set(completed);
        newCompleted.add(activeStep);
        setCompleted(newCompleted);

        /**
         * Sigh... it would be much nicer to replace the following if conditional with
         * `if (!this.allStepsComplete())` however state is not set when we do this,
         * thus we have to resort to not being very DRY.
         */
        if (completed.size !== totalSteps() - skippedSteps()) {
            handleNext();
        }
    };

    const handleReset = () => {
        //setActiveStep(0);
        props.setStage(0);
        setCompleted(new Set());
        setSkipped(new Set());
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    function isStepComplete(step) {
        return completed.has(step);
    }

    return (
        <div className={classes.root}>
            <Stepper alternativeLabel nonLinear activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const buttonProps = {};
                    if (isStepOptional(index)) {
                        buttonProps.optional = <Typography variant="caption">Optional</Typography>;
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepButton
                                onClick={handleStep(index)}
                                completed={isStepComplete(index)}
                                {...buttonProps}
                            >
                                {label}
                            </StepButton>
                            <Box style={{marginTop: '0.5rem'}} className='stepper-badge'>
                                {props.complete[stageMap[label]] ? (
                                    <Badge
                                        badgeContent={'Complete'}
                                        classes={{
                                            badge:
                                            classes.badgePositionRelative +
                                            " " +
                                            classes.badgeSuccess +
                                            " " +
                                            classes.badgeSizeSm,
                                        }}
                                        style={{fontSize: '0.8rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                                    ></Badge>
                                ) : (
                                    <Badge
                                        badgeContent={'Incomplete'}
                                        classes={{
                                            badge:
                                            classes.badgePositionRelative +
                                            " " +
                                            classes.badgeWarning +
                                            " " +
                                            classes.badgeSizeSm,
                                        }}
                                        style={{fontSize: '0.8rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                                    ></Badge>
                                )}
                            </Box>
                        </Step>
                    );
                })}
            </Stepper>
        </div>
    );
}
