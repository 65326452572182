import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Loader from 'components/general/Loader'
import CustomerAPI from 'config/api/CustomerAPI'
import { onError } from 'config/lib/errorLib'
import { NotificationManager } from 'react-notifications'
import { Auth } from 'aws-amplify'
import { 
    genderOptions, 
    maritalStatusOptions, 
    countyOptions, 
    countryOptions, 
} from 'config/constants/Constants'

import DetailsContext from 'config/context/DetailsContext'

import componentStyles from 'assets/theme/views/admin/profile.js'
import componentStylesCardImg from 'assets/theme/components/card-img.js'

const useStyles = makeStyles(componentStyles)
const useStylesCardImg = makeStyles(componentStylesCardImg)

function PersonalDetails(props) {
    const api = new CustomerAPI()
    const details = props.details
    const companyDetails = useContext(DetailsContext)
    const companyId = companyDetails.companyId
    const isBroker = props.broker

    const classes = { ...useStyles(), ...useStylesCardImg() }
    const theme = useTheme()

    const [email, setEmail] = useState(details.email || '')
    const [firstName, setFirstName] = useState(details.firstName || '')
    const [lastName, setLastName] = useState(details.lastName || '')
    const [dob, setDob] = useState(details.dob || '')
    const [gender, setGender] = useState(details.gender || '')
    const [maritalStatus, setMaritalStatus] = useState(details.maritalStatus || '')
    const [address, setAddress] = useState(details.address || '')
    const [city, setCity] = useState(details.city || '')
    const [county, setCounty] = useState(details.county || '')
    const [eircode, setEircode] = useState(details.eircode || '')
    const [country, setCountry] = useState(details.country || 'Ireland')
    const [nationality, setNationality] = useState(details.nationality || '')
    const [loading, setLoading] = useState(false)
    
    async function updateDetails() {
        setLoading(true)
        try {
            const info = await Auth.currentUserInfo()
            const phone = isBroker ? props.phone : info.attributes['phone_number']
            let details = {
                email,
                firstName,
                lastName,
                dob,
                gender,
                maritalStatus,
                address,
                city,
                county,
                eircode,
                country,
                nationality
            }
            let result = await api.updatePersonalDetails(companyId, phone, details)

            props.updateApplication(result)
            if (props.broker) {
                props.closeContent()
            }
            else {
                props.updateStage(1)
            }
            NotificationManager.success('Saving...')
        } catch(e) {
            onError(e)
        }
        setLoading(false)
    }

    function checkRequiredFields() {
        if (firstName.length > 0 && lastName.length > 0) {
            return false
        }
        else {
            return true
        }
    }

    function updateDob(val) {
        let updated = val.replace(/^(\d{2})(\d{2})/, '$1/$2/')
        setDob(updated)
        updatePersonalDetails('dob', updated)
    }

    function updatePersonalDetails(field, value) {
        let details = {
            email,
            firstName,
            lastName,
            dob,
            gender,
            maritalStatus,
            address,
            city,
            county,
            eircode,
            country,
            nationality
        }
        details[field] = value
        if (isBroker) {
            props.updatePersonalDetails(details)
        }
    }
    
    return (
        <Grid container>
            <Grid item xs={12} lg={12} style={{marginBottom: '1rem'}}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box
                            component={Typography}
                            variant='h6'
                            color={theme.palette.gray[600] + '!important'}
                            paddingTop='.25rem'
                            paddingBottom='.25rem'
                            fontSize='.75rem!important'
                            letterSpacing='.04em'
                            marginBottom='1.5rem!important'
                            classes={{ root: classes.typographyRootH6 }}
                        >
                            Basic Information
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>First Name *</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <OutlinedInput
                                    fullWidth
                                    type='text'
                                    value={firstName}
                                    onChange={(e) => { setFirstName(e.target.value); updatePersonalDetails('firstName', e.target.value); }}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>Last Name *</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <OutlinedInput
                                    fullWidth
                                    type='text'
                                    value={lastName}
                                    onChange={(e) => { setLastName(e.target.value); updatePersonalDetails('lastName', e.target.value); }}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>Phone</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <OutlinedInput
                                    fullWidth
                                    type='text'
                                    value={props.phone}
                                    disabled={true}
                                />
                            </FormControl>
                            {props.lastActive &&
                                <p className='success-text'>Verified</p>
                            }
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>Email</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <OutlinedInput
                                    fullWidth
                                    type='email'
                                    value={email}
                                    onChange={(e) => { setEmail(e.target.value); updatePersonalDetails('email', e.target.value); }}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>Date of Birth</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <OutlinedInput
                                    fullWidth
                                    type='text'
                                    value={dob}
                                    onChange={(e) => updateDob(e.target.value)}
                                    placeholder='DD / MM / YYYY'
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>Gender</FormLabel>
                            <FormControl variant='outlined' fullWidth>
                                <Select
                                    multiple={false}
                                    IconComponent={KeyboardArrowDown}
                                    value={gender}
                                    onChange={(e) => { setGender(e.target.value); updatePersonalDetails('gender', e.target.value); ; }}
                                >
                                    {genderOptions.map((option, i) => {
                                        return (<MenuItem value={option.value} key={i}>{option.label}</MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>Marital Status</FormLabel>
                            <FormControl variant='outlined' fullWidth>
                                <Select
                                    multiple={false}
                                    IconComponent={KeyboardArrowDown}
                                    value={maritalStatus}
                                    //error={maritalStatusValidation === 'invalid'}
                                    onChange={(e) => { setMaritalStatus(e.target.value); updatePersonalDetails('maritalStatus', e.target.value); ; }}
                                >
                                    {maritalStatusOptions.map((option, i) => {
                                        return (<MenuItem value={option.value} key={i}>{option.label}</MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>Nationality</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <OutlinedInput
                                    fullWidth
                                    type='text'
                                    value={nationality}
                                    onChange={(e) => { setNationality(e.target.value); updatePersonalDetails('nationality', e.target.value); ; }}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <Box
                            component={Typography}
                            variant='h6'
                            color={theme.palette.gray[600] + '!important'}
                            paddingTop='.25rem'
                            paddingBottom='.25rem'
                            fontSize='.75rem!important'
                            letterSpacing='.04em'
                            marginBottom='1.5rem!important'
                            classes={{ root: classes.typographyRootH6 }}
                        >
                            Address
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>Address Line 1</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <OutlinedInput
                                    fullWidth
                                    type='text'
                                    value={address}
                                    onChange={(e) => { setAddress(e.target.value); updatePersonalDetails('address', e.target.value); ; }}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>Town/City</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <OutlinedInput
                                    fullWidth
                                    type='text'
                                    value={city}
                                    onChange={(e) => { setCity(e.target.value); updatePersonalDetails('city', e.target.value); ; }}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>County</FormLabel>
                            <FormControl variant='outlined' fullWidth>
                                <Select
                                    multiple={false}
                                    IconComponent={KeyboardArrowDown}
                                    value={county}
                                    //error={countyValidation === 'invalid'}
                                    onChange={(e) => { setCounty(e.target.value); updatePersonalDetails('county', e.target.value); ; }}
                                >
                                    {countyOptions.map((option, i) => {
                                        return (<MenuItem value={option.value} key={i}>{option.label}</MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>Eircode</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <OutlinedInput
                                    fullWidth
                                    type='text'
                                    value={eircode}
                                    onChange={(e) => { setEircode(e.target.value); updatePersonalDetails('eircode', e.target.value); ; }}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>Country</FormLabel>
                            <FormControl variant='outlined' fullWidth>
                                <Select
                                    multiple={false}
                                    IconComponent={KeyboardArrowDown}
                                    value={country}
                                    onChange={(e) => { setCountry(e.target.value); updatePersonalDetails('country', e.target.value); ; }}
                                >
                                    {countryOptions.map((option, i) => {
                                        return (<MenuItem value={option.value} key={i}>{option.label}</MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} lg={12}>        
                <Grid container justifyContent='space-between'>
                    <Grid item>
                        {/*
                        <Button
                            onClick={() => setShowQuotesModal(true)}
                            color='primary'
                            disabled
                        >
                            Generate Quotes
                        </Button>
                        */}
                    </Grid>
                    <Grid item>
                        <Button
                            component={Box}
                            color='primary'
                            variant='contained'
                            width={180}
                            disabled={checkRequiredFields() || loading}
                            onClick={() => updateDetails()}
                        >
                            {!loading ? (
                                <>{!props.broker ? (
                                    <>Save & Continue</>
                                ) : (
                                    <>Update</>
                                )}</>
                            ) : (
                                <Loader height={12} />
                            )}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PersonalDetails