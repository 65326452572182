import React, { useState, useContext } from 'react'
import Box from '@material-ui/core/Box'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Button from '@material-ui/core/Button'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import CustomerAPI from 'config/api/CustomerAPI'
import { onError } from 'config/lib/errorLib'

import DetailsContext from 'config/context/DetailsContext'

function CallPreferenceForm(props) {
    const api = new CustomerAPI()
    const companyDetails = useContext(DetailsContext)
    const companyId = companyDetails.companyId

    const [note, setNote] = useState(props.note || '')
    const [disabled, setDisabled] = useState(props.note ? true : false)
    const [alert, setAlert] = useState(false)

    async function submitNote() {
        try {
            await api.addCallPreferenceNote(companyId, note)
            openSuccessAlert('Your note has been sent to your advisor')
        } catch(e) {
            onError(e)
        }

        setDisabled(true)
    }

    function checkFormFields() {
        if (note.length > 0) {
            return false
        }
        else {
            return true
        }
    }

    function openSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(false)}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

    return (
        <>
            {alert}
            <FormGroup>
                <FormLabel>Call Preference</FormLabel>
                <OutlinedInput
                    fullWidth
                    multiline
                    rows='5'
                    type='text'
                    placeholder='Please call me after 5pm...'
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    disabled={disabled}
                />
            </FormGroup>
            {!disabled &&
                <Button
                    component={Box}
                    color='primary'
                    marginLeft='auto!important'
                    variant='contained'
                    onClick={() => submitNote()}
                    disabled={checkFormFields()}
                >
                    Add Note
                </Button>
            }
        </>
    )
}

export default CallPreferenceForm