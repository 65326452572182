import React, { useState, useEffect, useContext, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import FormLabel from '@material-ui/core/FormLabel'
import Loader from 'components/general/Loader'
import CustomerAPI from 'config/api/CustomerAPI'
import { onError } from 'config/lib/errorLib'
import ApplicationCard from 'components/customer/ApplicationCard'
import ApplicationSubmittedCard from 'components/customer/ApplicationSubmittedCard'
import Stepper from 'components/customer/Stepper'
import { getComplete, getCompanyCompliance } from 'config/helpers/Helpers'

import DetailsContext from 'config/context/DetailsContext'

import nphLogo from 'resources/images/nph-logo.png'
import pcLogo from 'resources/images/pc-logo.png'
import qfLogo from 'resources/images/qf-logo.png'

import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

function Application() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    const theme = useTheme()
    const api = new CustomerAPI()
    const companyDetails = useContext(DetailsContext)
    const companyId = companyDetails.companyId
    const applicationForm = useRef(null)

    const [stage, setStage] = useState(0)
    const [loading, setLoading] = useState(true)
    const [application, setApplication] = useState(null)
    const [complete, setComplete] = useState(null)

    useEffect(() => {
        getApplication()
    }, [])

    async function getApplication() {
        try {
            let response = await api.getApplication(companyId)
            console.log('application: ', response)

            // if (!response) {
            //     response = await api.addApplication(companyId)
            //     console.log('new application: ', response)
            // }

            try {
                await api.updateLastActive(companyId)
            } catch(e) {
                onError(e)
            }

            let appComplete = getComplete(response)
            console.log('app complete: ', appComplete)

            setApplication(response)
            setComplete(appComplete)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    async function updateSubmissionStatus(status) {
        console.log('Submit application')
        try {
            let response = await api.updateSubmissionStatus(companyId, status)
            console.log('application: ', response)

            setApplication(response)
            applicationForm.current.scrollIntoView()
        } catch(e) {
            onError(e)
        }
    }

    function updateStage(stage) {
        setStage(stage)
        applicationForm.current.scrollIntoView()
    }

    function updateApplication(application) {
        setApplication(application)
        let appComplete = getComplete(application)
        setComplete(appComplete)
    }

    function getSiteLogo() {
        let logo = null
        if (companyId === 'qf') {
            logo = qfLogo
        }
        if (companyId === 'nph') {
            logo = nphLogo
        }
        if (companyId === 'al') {
            logo = pcLogo
        }
        if (companyId === 'bq') {
            logo = null
        }
        return logo
    }

    return (
        <>
            <Container
                maxWidth={false}
                component={Box}
                //marginTop='-6rem'
                marginTop='2rem'
                paddingBottom='3rem'
                classes={{ root: classes.containerRoot }}
            >
                {!loading ? (
                    <Grid 
                        container 
                        style={{marginTop: '2rem'}}
                        //alignItems='center'
                        justifyContent='center'
                        
                    >
                        {(!application.submitted && (application.type !== 'Pension Transfer' && application.type !== 'Retirement Planning')) &&
                            <Grid item xs={12} style={{marginBottom: '1rem'}}>
                                <Stepper 
                                    stage={stage}
                                    setStage={(stage) => setStage(stage)}
                                    complete={complete}
                                    companyId={companyId}
                                />
                            </Grid>
                        }
                        <Grid item xs={12} md={3}>
                            <Grid container direction='column' justifyContent='center' alignItems='center'>
                                <Grid item xs={12}>
                                    <Box 
                                        display='flex'
                                        justifyContent='center'
                                        alignItems='center'
                                        style={{marginBottom: '2rem'}}
                                    >
                                        <img className='login-logo' src={getSiteLogo()} alt='broker-logo' />
                                    </Box>
                                </Grid>
                                {companyId === 'qf' && 
                                    <Grid item xs={12}>
                                        <Box 
                                            display='flex'
                                            justifyContent='center'
                                            alignItems='center'
                                            style={{marginBottom: '2rem'}}
                                        >
                                            <Box
                                                fontSize='0.75rem'
                                                color={'#076b9d'}
                                                marginBottom='1.5rem'
                                                //marginTop='2rem'
                                                textAlign='center'
                                                //style={{textDecorationStyle: 'underline'}}
                                                className='powered-by'
                                            >
                                                <Box component='small' fontSize='100%' className='q-tip'>
                                                    Q Financial is a trading name of OMA Financial Services Ltd and is regulated by the Central Bank of Ireland. Reference number – C135240. Registered in Ireland. Company Registration number – 540749.
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                }
                                {companyId === 'al' && 
                                    <Grid item xs={12}>
                                        <Box 
                                            display='flex'
                                            justifyContent='center'
                                            alignItems='center'
                                            style={{marginBottom: '2rem'}}
                                        >
                                            <Box
                                                fontSize='1rem'
                                                color={'#076b9d'}
                                                marginBottom='1.5rem'
                                                //marginTop='2rem'
                                                textAlign='left'
                                                //style={{textDecorationStyle: 'underline'}}
                                                className='powered-by'
                                            >
                                                <Box component='small' fontSize='100%' className='q-tip'>
                                                    Powered by <span class='underline-text'>Aspect Life & Pensions</span> <span class='tooltiptext'>Aspect Financial Service Limited t/a Aspect Life & Pensions, Aspect Mortgages is regulated by The Central Bank of Ireland. Registered Number C501905</span>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    {/*
                                    <Box className='video-box' style={{marginBottom: '2rem'}}>
                                        <Box
                                            fontSize='1rem'
                                            color={'#bbb'}
                                            //marginTop='2rem'
                                            textAlign='center'
                                            //style={{textDecorationStyle: 'underline'}}
                                        >
                                            <Box component='small' fontSize='100%' className='q-tip'>
                                                Explainer Video
                                            </Box>
                                        </Box>
                                    </Box>
                                    */}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={8} ref={applicationForm} style={{marginBottom: '2rem'}}>
                            {(!application.submitted && (application.type !== 'Pension Transfer' && application.type !== 'Retirement Planning')) ? (
                                <ApplicationCard 
                                    application={application}
                                    stage={stage}
                                    setStage={(stage) => updateStage(stage)}
                                    updateApplication={(application) => updateApplication(application)}
                                    submitApplication={() => updateSubmissionStatus(true)}
                                />
                            ) : (
                                <ApplicationSubmittedCard 
                                    application={application}
                                    updateApplication={(application) => updateApplication(application)}
                                    unsubmitApplication={() => updateSubmissionStatus(false)}
                                />
                            )}
                        </Grid>

                        <Box
                            //fontSize='12px'
                            color={theme.palette.gray[600]}
                            marginBottom='0rem'
                            marginTop='2rem'
                            textAlign='center'
                            maxWidth='80%'
                            //className='online-discount'
                        >
                            <FormLabel>
                                {getCompanyCompliance(companyId)}
                            </FormLabel>
                        </Box>
                    </Grid>
                ) : (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                                <Loader />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </>
    );
}

export default Application